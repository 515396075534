import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { capitalize } from "../../utils/capitalize";
import { useDebounce } from "../../hooks/useDebounce";
import { translate } from "../../translations/translate";
import SearchTagGroup from "./SearchTagGroup/SearchTagGroup";
import SearchCard from "../SearchCard";

const SearchAutocomplete = (props) => {
  const { label, selectedItems, removeSelectedItem, searchApi, handleClick } = props;

  // States
  const [searchItems, setSearchItems] = useState("");
  const [searchItemsResult, setSearchItemsResult] = useState([]);
  const [isResultEmpty, setIsResultEmpty] = useState(false);

  // Redux Selectors
  const lang = useSelector((state) => state.languageReducer.lang);

  // Debouncer
  const debouncedSearch = useDebounce(searchItems);
  // Translate Hook
  const __ = (key) => translate(lang, key);

  const handleItemClick = (itemObj) => {
    if (!selectedItems.find(item => item._id === itemObj._id)) {
      handleClick(itemObj);
      setSearchItems("");
      // setSearchItemsResult([]);
    }
  };

  const handleClientSearch = (e) => {
    setSearchItems(e.target.value);
  };

  // Client Search Effect
  useEffect(() => {
    if (debouncedSearch.length > 0) {
      (async () => {
        const result = await searchApi(debouncedSearch);
        setSearchItemsResult(result);

        if (result.length === 0) {
          setIsResultEmpty(true);
        } else {
          setIsResultEmpty(false);
        }
      })();
    } else {
      setSearchItemsResult([]);
      setIsResultEmpty(false);
    }
  }, [debouncedSearch]);

  return (
    <div className="input-item">
      {/* Search Field */}
      <div className="component-input smart-search-container">
        <label
          className="input-label"
        >
          <input
            type="text"
            className="input"
            value={searchItems}
            onChange={handleClientSearch}
            placeholder={__("Start typing...",)}
          />
          <span className="input-text">
            {__(`Search ${capitalize(label)}`)}
          </span>
        </label>
        {/* Autocomplete options */}
        {searchItemsResult?.length > 0 && (
          <div className="smart-search-list">
            {searchItemsResult?.map((item, idx) => {
              const name = item?.name?.first && item?.name?.last
                ? item.name.first + " " + item.name.last
                : item.name;

              return (
                <Box
                  display="flex"
                  justifyContent="center"
                  key={idx}
                >
                  <SearchCard
                    label={name}
                    classNames={"with-padding bordered hoverable"}
                    onClick={() => handleItemClick(item)}
                  />
                </Box>
              );
            })}
          </div>
        )}
        {/* If there are not any options */}
        {isResultEmpty && (
          <div className="smart-search-list">
            <Box
              display="flex"
              justifyContent="center"
              padding="11px 12px"
              fontSize={14}
            >
              {__("Nothing found")}
            </Box>
          </div>
        )}
      </div>
      {/* Selected Items Group */}
      <SearchTagGroup
        tagsArray={selectedItems}
        removeTag={removeSelectedItem}
        label={label}
      />
    </div>
  );
};

export default SearchAutocomplete;
