import React, { useState, useEffect } from "react";
import { Box, colors, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getParsedDate } from "../../../utils/getParsedDate";
import { translate } from "../../../translations/translate";
import { updateTotalAction } from "../../../redux/reducers/paginationControllerReducer";
import StatusTag from "../../../components/StatusTag";
import Action from "../../../components/Action/index.jsx";
import BreadCrumbs from "../../../components/BreadCrumbs/index.jsx";
import Filter from "../../../components/Filter/index.jsx";
import config from "../../../config";
import Modal from "react-modal";
import ModalWindow from "../../../components/ModalWindow";
import useApiHook from "../../../hooks/useApiHook";
import Preloader from "../../../components/Preloader";
import "../../../assets/fonts/epos-icons/style.css";
import "./styles.sass";
import { ApiService } from "../../../utils/ApiService";
import { errorToast } from "../../../utils/toast";
import { setVersions } from "../../../redux/actions/filtersAction";

Modal.setAppElement("#root");

const PageCashdeskTable = () => {
    const filters = {
        isExpiresAt: true,
        isStatus: true,
        isApproved: true,
        versions  : true
    };
    const page = "cashdesks";

    const navigate = useNavigate();
    const {id: businessID} = useParams();
    const { state } = useLocation();

    const { token } = useSelector((state) => state.authReducer);
    const storedUserData = useSelector((state) => state.authReducer);
    const filterState = useSelector((state) => state.filter);
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);
    const isClient = storedUserData.role === "client";
    const filterByStatus = filterState.status.filter(item => item.isChecked)
        .map(item => "&status=" + item.name.toLowerCase()).join("");
    const filterByApprove = filterState.approved.filter(item => item.isChecked)
        .map((item) => {
            if (item.name === "Not Approved")
                return "&isApproved=false";
            return "&isApproved=true";
        }).join("");
    const { from, to } = filterState.expiresAt;
    const filterByDate = `${from ? `&fromDate=${from}` : ""}${to ? `&toDate=${to}` : ""}`;
    const filterByVersions = filterState.cashdeskVersions.filter(item => item.isChecked)
        .map(item => "&version=" + item.name.toLowerCase()).join("");
    const [id, setId] = useState();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [combineFilter, setCombineFilter] = useState("");

    const removeCashdesk = () => {
        ApiService.deleteEvent(`/cashdesks/${id}`, token)
            .then((res) => {
                if (res.status === 200) {
                    window.location.reload();
                }
            })
            .catch((error) => {
                errorToast(error);
            });
    };

    const openModals = (id) => {
        setModalIsOpen(true);
        setId(id);
    };
    const closeModal = () => {
        setModalIsOpen(false);
    };
    let urlBase = `${config.API_BASE_URL}/cashdesks?`;
    if (state?.business) urlBase += `businessID=${state?.business._id}`;
    if (state?.branch) urlBase += `&branchID=${state?.branch._id}`;
    const versionUrl = `${config.API_BASE_URL}/cashdesks/versions/cashdesk`;
    const [url, setUrl] = useState(urlBase);
    const { data, loading, error } = useApiHook(url);
    const { data:versionData } = useApiHook(versionUrl);
    const dispatch = useDispatch();
    const controller = useSelector(
        (state) => state.paginationControllerReducer,
    );
    const cashdesks = data?.data?.docs;

    useEffect(() => {
        dispatch(updateTotalAction(parseInt(data?.data?.totalDocs) || 0));
    }, [data]);

    useEffect(() => {
        const versions  = versionData?.data?.versions?.filter((item) => item.appVersion).map(({ appVersion })=> ({
            name : `${appVersion}`,
            isChecked: false
        }));

        if(versions) {
            dispatch(setVersions(versions));
        }
    }, [versionData]);

    useEffect(() => {
        setUrl(
            `${urlBase}&page=${controller.page + 1}&limit=${controller.pageSize}${combineFilter}`,
        );
    }, [controller.page, controller.pageSize]);

    useEffect(() => {
        if(searchInputValue)
            setUrl(`${config.API_BASE_URL}/cashdesks/search/cashdesk?query=${searchInputValue}`);
        else setUrl(urlBase);
    }, [searchInputValue]);

    useEffect(() => {
        const filters = filterByStatus + filterByApprove + filterByDate + filterByVersions ;

        setCombineFilter(filters);
        setUrl(urlBase + filters);
    }, [filterByStatus, filterByApprove, filterByDate, filterByVersions]);

    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );

    if (error) return <div>{error}</div>;

    let cashdesksList = [];

    if (cashdesks) {
        cashdesks.map((item) => {
            let cashdesk = {
                uniqueID: item.uniqueID || "—",
                branchID: item.branchID?._id || "—",
                branchSlug: item.branchID?.slug || "",
                branchName: item.branchID?.name || "—",
                businessID: item.businessID?._id,
                businessSlug: item.businessID?.slug || "",
                businessName: item.businessID?.name || "—",
                expiresAt: getParsedDate(item.licence?.expiresAt) || "—",
                approved: item.approved?.isApproved ? "Approved" : "Not approved",
                id: item._id,
                status: item.status || "—",
                appVersion : item.appletInfo?.appVersion
            };
            cashdesksList.push(cashdesk);
        });
    }
    const columns = [
        {
            field: "uniqueID",
            headerName: __("Cashdesk ID"),
            flex: 1,
            cellClassName: "mane-column--cell",
            renderCell: ({ row }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                    >
                        <Link
                            to={state?.business
                              ? `/businesses/${businessID || state.business?._id}/cashdesks/${row.uniqueID}`
                              : `/cashdesks/${row.id}`}
                            state={{
                                cashdesk: {
                                    id: row.id,
                                },
                                business: state?.business
                            }}
                            className="link"
                        >
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={600}
                                fontSize={14}
                                color={colors.common["black"]}
                                textOverflow={"ellipsis"}
                                overflow={"hidden"}
                                whiteSpace={"nowrap"}
                            >
                                {row.uniqueID}
                            </Typography>
                        </Link>
                    </Box>
                );
            },
        },
        {
            field: "branchName",
            headerName: __("Branch"),
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                        onClick={() => {
                            navigate(
                                `/branches/${row.branchID}`,
                                {
                                    state: {
                                        branch: {
                                            id: row.branchID,
                                            slug: row.branchSlug,
                                        },
                                    },
                                },
                            );
                        }}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={500}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {row.branchName}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "businessName",
            headerName: __("Business"),
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                        onClick={() => {
                            navigate(
                                `/businesses/${
                                    row.businessSlug || row.businessID
                                }`,
                                {
                                    state: {
                                        business: {
                                            id: row.businessID,
                                            slug: row.businessSlug,
                                        },
                                    },
                                },
                            );
                        }}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {row.businessName}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "expiresAt",
            headerName: __("Expires"),
            flex: 1,
            renderCell: ({ row: { expiresAt } }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {expiresAt}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "approved",
            headerName: __("Approved"),
            flex: 1,
            renderCell: ({ row: { approved } }) => {
                return (
                    <Box display="flex" justifyContent="flex-start">
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                        >
                            {__(approved)}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "status",
            headerName: __("Status"),
            type: "string",
            flex: 1,
            renderCell: (props) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <StatusTag className={"sm"} text={props.value} />
                    </Box>
                );
            },
        },
        {
            field: "version",
            headerName: __("Version"),
            renderCell: ({ row: { appVersion } }) => {
                return (
                    <Box display="flex" justifyContent="flex-start">
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={12}
                            color={colors.common["black"]}
                        >
                            {appVersion ? appVersion : "-"}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "action",
            headerName: __("Action"),
            type: "string",
            renderCell: ({ row, ...props }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Action
                            onClick={() =>
                                navigate(
                                    `/cashdesks/${row.id}/edit`,
                                    {
                                        state: {
                                            cashdesk: {
                                                id: row.id,
                                                slug: row.slug,
                                            },
                                        },
                                    },
                                )
                            }
                            onClickView={() =>
                                navigate(`/cashdesks/${row.slug || row.id}`, {
                                    state: {
                                        ...state,
                                        cashdesk: {
                                            id: row.id,
                                            slug: row.slug,
                                        },
                                    },
                                })
                            }
                            text={props.value}
                            openModal={() => openModals(row?.id)}
                            viewLink={`/cashdesks/${props?.row?.uniqueID}`}
                            edit={!isClient}
                            deleteAction={!isClient}
                        />
                    </Box>
                );
            },
        },

    ];

    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[{ label: "Cashdesks", item: "/cashdesks" }]}
            />
            <div
                className={`page page-cashdesk-table ${isClient ? "grid" : ""}`}
            >
                <Box>
                    <Filter
                        description={__("Cashdesks")}
                        filters={filters}
                        page={page}
                        search={searchInputValue}
                        setSearch={setSearchInputValue}
                        buttons={
                            state?.business?._id && state?.branch?._id
                                ? [
                                      {
                                          link: "/cashdesks/create",
                                          text: __("Add Cashdesk"),
                                          state:
                                              state?.business && state?.branch
                                                  ? {
                                                        business: {
                                                            slug: state.business
                                                                ?._id,
                                                            _id: state?.business
                                                                ?._id,
                                                        },
                                                        branch: {
                                                            ...state.branch,
                                                        },
                                                    }
                                                  : null,
                                      },
                                  ]
                                : []
                        }
                    />
                    <Box
                        sx={{
                            "&": {
                                margin: "0",
                                height: "90vh",
                                minHeight: "350px",
                            },
                            "& .name-column--cell": {
                                color: colors.green[300],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                            },
                        }}
                    >
                        <DataGrid
                            rows={cashdesksList}
                            columns={columns}
                            rowCount={controller.total}
                            page={controller.page}
                            pageSize={controller.pageSize}
                            {...config.TABLE_CONFIG(__)}
                        />
                    </Box>
                </Box>
                <Modal
                    className="delete-modal"
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                >
                    <div className="modal-bottom">
                        <ModalWindow
                            title={__("Are you sure?")}
                            text={__(
                                "Are you sure you want to permanently delete this cashdesk?",
                            )}
                            onClick={closeModal}
                            onClickDelete={removeCashdesk}
                        />
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default PageCashdeskTable;
