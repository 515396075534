import React from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import { useSelector } from "react-redux";
import { getParsedDate } from "../../../utils/getParsedDate";
import {translate} from "../../../translations/translate";
import PageHeader from "../../../components/PageHeader";
import StatusTag from "../../../components/StatusTag";
import useApiHook from "../../../hooks/useApiHook";
import config from "../../../config";
import Preloader from "../../../components/Preloader";
import BreadCrumbs from "../../../components/BreadCrumbs";
import "./styles.sass";
import noImage from "../../../assets/images/no-image.png";
import {Box, colors, Typography} from "@mui/material";
import Filter from "../../../components/Filter";
import {DataGrid} from "@mui/x-data-grid";
import Action from "../../../components/Action";
import {useNavigate} from "react-router";

const PageViews = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const {id} = useParams();
    const url = `${config.API_BASE_URL}/users/${state?.user?.id || id}`;
    const { data, loading, error } = useApiHook(url);
    const lang = useSelector((state) => state.languageReducer.lang);
    const controller = useSelector((state) => state.paginationControllerReducer);
    const __ = (key) => translate(lang, key);

    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );
    if (error) return <div>{error}</div>;

    const user = data?.data?.docs[0];

    const isDealer = user?.role?.title === "Dealer";

    // Clients table column and row
    const clientColumns = [
        {
            field: "clientName",
            headerName: __("Name"),
            flex: 1,
            cellClassName: "mane-column--cell",
            renderCell: ({ row }) => {
                let fullName = row.firstName + " " + row.lastName;
                if(fullName.length > 37) {
                    fullName = fullName.slice(0, 35) + "...";
                }
                return (
                  <Box display="flex" justifyContent="center">
                      <Link
                        to={`/clients/${row.id}`}
                        state={{
                            client: {
                                id: row.id,
                            },
                        }}
                      >
                          <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={600}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                          >
                              {fullName}
                          </Typography>
                      </Link>
                  </Box>
                );
            },
        },
        {
            field: "clientPhone",
            headerName: __("Phone Number"),
            className: "table-phone",
            flex: 1,
            renderCell: ({ row: { phone } }) => {
                return (
                  <Box display="flex" justifyContent="flex-start" width={"100%"}>
                      <Typography
                        fontFamily="Manrope, sans-serif"
                        fontWeight={400}
                        fontSize={14}
                        color={colors.grey[500]}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                        whiteSpace={"nowrap"}
                      >
                          {phone}
                      </Typography>
                  </Box>
                );
            },
        },
        {
            field: "action",
            headerName: __("Action"),
            type: "string",
            renderCell: ({ row }) => {
                return (
                  <Box display="flex" justifyContent="center">
                      <Action
                        edit={false}
                        deleteAction={false}
                        onClick={() =>
                          navigate(
                            `/clients/${row.slug || row.id}/edit`,
                            {
                                state: {
                                    client: {
                                        id: row.id,
                                        slug: row.slug,
                                    },
                                },
                            },
                          )
                        }
                        onClickView={() =>
                          navigate(`/clients/${row.slug || row.id}`, {
                              state: {
                                  client: {
                                      id: row.id,
                                      slug: row.slug,
                                  },
                              },
                          })
                        }
                      />
                  </Box>
                );
            },
        },
        // {
        //     field: "businessName",
        //     headerName: __("Business"),
        //     flex: 1,
        //     renderCell: ({ row }) => {
        //         return (
        //           <Box
        //             display="flex"
        //             justifyContent="flex-start"
        //             width={"100%"}
        //           >
        //               <Link
        //                 to={`/businesses/${row.businessSlug || row.businessID
        //                 }`}
        //                 state={{
        //                     business: {
        //                         id: row.businessID,
        //                         slug: row.businessSlug,
        //                     },
        //                 }}
        //                 className="link"
        //               >
        //                   <Typography
        //                     fontFamily="Manrope, sans-serif"
        //                     fontWeight={400}
        //                     fontSize={14}
        //                     color={colors.common["black"]}
        //                     textOverflow={"ellipsis"}
        //                     overflow={"hidden"}
        //                     whiteSpace={"nowrap"}
        //                   >
        //                       {row.businessName}
        //                   </Typography>
        //               </Link>
        //           </Box>
        //         );
        //     },
        // }
    ];

    let clientList = [];

    user?.permittedClients?.map((item) => {
        let clientSingle = {
            firstName: item.name?.first || "—",
            lastName: item.name?.last || "—",
            id: item._id,
            phone: item.phone || "—",
        };
        clientList.push(clientSingle);
    });

    // Businesses table column and row
    const businessColumns = [
        {
            field: "businessName",
            headerName: __("Name"),
            flex: 2.2,
            cellClassName: "mane-column--cell",
            renderCell: ({ row, row: { name, slug } }) => {
                return (
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    width={"100%"}
                  >
                      <Link
                        to={`/businesses/${slug}`}
                        state={{
                            business: {
                                id: row.id,
                            },
                        }}
                        className="link"
                      >
                          <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={600}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                          >
                              {name}
                          </Typography>
                      </Link>
                  </Box>
                );
            },
        },
        {
            field: "number",
            headerName: __("Phone"),
            flex: 2,
            renderCell: ({ row: { phone } }) => {
                return (
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    width={"100%"}
                  >
                      <Typography
                        fontFamily="Manrope, sans-serif"
                        fontWeight={400}
                        fontSize={14}
                        color={colors.common["black"]}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                        whiteSpace={"nowrap"}
                      >
                          {phone}
                      </Typography>
                  </Box>
                );
            },
        },
        {
            field: "tin",
            headerName: __("TIN/PINFL"),
            flex: 2,
            renderCell: ({ row: { tin } }) => {
                return (
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    width={"100%"}
                  >
                      <Typography
                        fontFamily="Manrope, sans-serif"
                        fontWeight={400}
                        fontSize={14}
                        color={colors.common["black"]}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                        whiteSpace={"nowrap"}
                      >
                          {tin}
                      </Typography>
                  </Box>
                );
            },
        },
        {
            field: "action",
            headerName: __("Action"),
            type: "string",
            renderCell: ({ row }) => {
                return (
                  <Box display="flex" justifyContent="center">
                      <Action
                        edit={false}
                        deleteAction={false}
                        onClick={() =>
                          navigate(
                            `/businesses/${row.slug || row.id}/edit`,
                            {
                                state: {
                                    business: {
                                        id: row.id,
                                        slug: row.slug,
                                    },
                                },
                            },
                          )
                        }
                        onClickView={() =>
                          navigate(`/businesses/${row.slug || row.id}`, {
                              state: {
                                  business: {
                                      id: row.id,
                                      slug: row.slug,
                                  },
                              },
                          })
                        }
                      />
                  </Box>
                );
            },
        },
    ];

    let businessList = [];

    user?.permittedBusinesses?.map((item) => {
        let businessSingle = {
            id: item._id,
            name: item.name || "—",
            slug: item.slug,
            phone: item.phone || "—",
            tin: item.tin || "-"
        };
        businessList.push(businessSingle);
    });

    const buttons = [
        {
            name: "Back",
            link: "/users",
            state: {},
            className: "outline"
        },
        {
            name: "Edit",
            link: `/users/${state?.user?.id || id}/edit`,
            state: {
                user: {
                    id: state?.user?.id || id
                }
            },
            className: ""
        }
    ];
    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Users", link: "/users" },
                    { label: user?.name?.first },
                ]}
            />

            <div className="page component-page-user-create">
                <div className="page-inner">
                    <PageHeader
                        name={__("View User")}
                        buttons={buttons}
                    />
                    <div className="cards">
                        <div className="left-side-cards">
                            <div className="component-box-card">
                                <div className="card-info card-info-users-view">
                                    <div className="card-header">
                                        <div className="card-info-inner inner-users-view">
                                            <div className="card-icon">
                                                <i className="icon-profile" />
                                            </div>
                                            <div className="card-text">
                                                <p className="card-title">
                                                    {__("Personal Information")}
                                                </p>
                                                <p className="card-subtitle"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <StatusTag
                                        className={"sm"}
                                        text={user?.status}
                                    />
                                </div>
                                <div className="input-column">
                                    <div className="card-avatar">
                                        <img
                                            className="avatar-img"

                                            src={user?.avatar?.fileInfo?.url
                                                ? config.API_HOST + "/" + user?.avatar?.fileInfo?.url
                                                : noImage}
                                            alt="avatar-img"
                                        />
                                    </div>
                                    <div className="input-row">
                                        <div className="input-item">
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        value={user?.name?.first}
                                                        name="firstName"
                                                        className="input input-disabled"
                                                        type="text"
                                                        disabled
                                                    />
                                                    <span className="input-text">
                                                        {__("First Name")}
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        defaultValue={
                                                            user?.name?.last
                                                        }
                                                        className="input input-disabled"
                                                        type="text"
                                                        disabled
                                                    />
                                                    <span className="input-text">
                                                        {__("Last Name")}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="input-item">
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        defaultValue={
                                                            user?.name?.middle
                                                        }
                                                        className="input input-disabled"
                                                        type="text"
                                                        disabled
                                                    />
                                                    <span className="input-text">
                                                        {__("Middle Name")}
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        defaultValue={
                                                            user?.email
                                                        }
                                                        className="input input-disabled"
                                                        type="text"
                                                        disabled
                                                    />
                                                    <span className="input-text">
                                                        {__("Email")}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="input-item">
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        defaultValue={
                                                            user?.phone
                                                        }
                                                        className="input input-disabled"
                                                        type="text"
                                                        disabled
                                                    />
                                                    <span className="input-text">
                                                        {__("Phone")}
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <span className="input-text date-picker">
                                                        {__("Date of Birth")}
                                                    </span>
                                                    <input
                                                        defaultValue={getParsedDate(
                                                            user?.birthDate,
                                                        )}
                                                        className="input input-disabled"
                                                        type="text"
                                                        disabled
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-profile" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Position")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <span className="input-text">
                                                {__("Position")}
                                            </span>
                                            <input
                                                defaultValue={__(user?.role?.title)}
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {isDealer && (
                              <div className="table-flex">
                                  {/* Clients Table */}
                                  <div className="page page-table-branch table branches-table">
                                      <Box>
                                          <Filter
                                            description={__("Clients")}
                                            filters={{
                                                isBusiness: true,
                                                isStatus: true,
                                            }}
                                            page={"clients"}
                                            search={""}
                                            setSearch={() => {}}
                                            withoutButton
                                          />
                                          <Box
                                            sx={{
                                                "&": {
                                                    margin: "0",
                                                },
                                                "& .name-column--cell": {
                                                    color: colors.green[300],
                                                },
                                                "& .MuiDataGrid-footerContainer": {
                                                    borderTop: "none",
                                                },
                                            }}
                                          >
                                              <DataGrid
                                                rows={clientList}
                                                rowCount={controller.total}
                                                page={controller.page}
                                                pageSize={controller.pageSize}
                                                columns={clientColumns}
                                                {...config.TABLE_CONFIG(__)}
                                              />
                                          </Box>
                                      </Box>
                                  </div>
                                  {/* Businesses Table */}
                                  <div className="page page-table-branch table branches-table">
                                      <Box>
                                          <Filter
                                            description={__("Businesses")}
                                            filters={{
                                                isBusiness: true,
                                                isStatus: true,
                                            }}
                                            page={"businesses"}
                                            search={""}
                                            setSearch={() => {}}
                                            withoutButton
                                          />
                                          <Box
                                            sx={{
                                                "&": {
                                                    margin: "0",
                                                },
                                                "& .name-column--cell": {
                                                    color: colors.green[300],
                                                },
                                                "& .MuiDataGrid-footerContainer": {
                                                    borderTop: "none",
                                                },
                                            }}
                                          >
                                              <DataGrid
                                                rows={businessList}
                                                rowCount={controller.total}
                                                page={controller.page}
                                                pageSize={controller.pageSize}
                                                columns={businessColumns}
                                                {...config.TABLE_CONFIG(__)}
                                              />
                                          </Box>
                                      </Box>
                                  </div>
                              </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageViews;
