import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { ApiService } from "../../../utils/ApiService";
import { errorToast, successToast, warnToast } from "../../../utils/toast";
import { Box, colors, Typography } from "@mui/material";
import { translate } from "../../../translations/translate";
import { capitalize } from "../../../utils/capitalize";
import { NumericFormat } from "react-number-format";
import Modal from "react-modal";
import ModalWindow from "../../../components/ModalWindow";
import Action from "../../../components/Action/index.jsx";
import BreadCrumbs from "../../../components/BreadCrumbs/index.jsx";
import Filter from "../../../components/Filter/index.jsx";
import useApiHook from "../../../hooks/useApiHook";
import config, { getLocaleText } from "../../../config";
import Preloader from "../../../components/Preloader";
import Button from "../../../components/global/Button";
import DragAndDrop from "../../DragAndDrop";
import axios from "axios";
import "../../../assets/fonts/epos-icons/style.css";
import "./styles.sass";
import { updateTotalAction } from "../../../redux/reducers/paginationControllerReducer";
import { exportToExcel } from "../../../utils/exportToExcel";

Modal.setAppElement("#root");

const PageProductTable = () => {
    const filters = {
        isProductType: true,
        isProductCategory: true,
        isPriceRange: true,
        isStatus: true,
    };
    const navigate = useNavigate();
    const { state } = useLocation();
    const { businessID } = useParams();
    const storedUserData = useSelector((state) => state.authReducer);
    const token = storedUserData.token;

    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [id, setId] = useState();

    const [importModalOpen, setImportModalOpen] = useState(false);
    const [exportModalOpen, setExportModalOpen] = useState(false);
    const [shopDragNDropOpen, setShopDragNDropOpen] = useState(false);
    const [isDragNDropOpen, setIsDragNDropOpen] = useState(false);
    const [shopImportFile, setShopImportFile] = useState(null);
    const [productsImportFile, setProductImportFile] = useState(null);
    const [partiallyUpload, setPartiallyUpload] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [checkedProducts, setCheckedProducts] = useState([]);

    const handleDragNDropOpen = () => {
        setIsDragNDropOpen(!isDragNDropOpen);
    };
    const handleImportFile = (file) => {
        setProductImportFile(file);
    };

    useEffect(() => {
        if (shopImportFile) {
            setIsLoading(true);
            axios
                .post(
                    `${config.API_BASE_URL}/businesses/${state.business._id || businessID
                    }/products/import/shop/template`,
                    {
                        uploadType: "import",
                        file: shopImportFile,
                        docModel: capitalize(storedUserData.role) || "User",
                        ownerID: storedUserData.ID || "",
                    },
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Bearer ${token}`,
                        },
                    },
                )
                .then((result) => {
                    setIsLoading(false);
                    if (result.status === 201) {
                        successToast(__("File was successfully uploaded"));
                        setTimeout(() => window.location.reload(), 700);
                    }
                    if (result.status === 206) {
                        warnToast(result.data.message || "File was partially uploaded");
                        setPartiallyUpload({
                            failedProducts: result.data.data.failed,
                            total: result.data.data.total,
                            successful: result.data.data.successful
                        });
                    }
                })
                .catch((e) => {
                    setIsLoading(false);
                    const res = e.response.data;
                    errorToast(
                        __("Something went wrong. ") + res.message,
                    );

                    if (res.data.failed) {
                        setPartiallyUpload({
                            message: res.message,
                            failedProducts: res.data.failed,
                            total: res.data.total,
                            successful: res.data.successful
                        });
                    }
                });
        }
    }, [shopImportFile]);

    useEffect(() => {
        if (productsImportFile) {
            setIsLoading(true);
            axios
                .post(
                    `${config.API_BASE_URL}/businesses/${state.business._id || businessID
                    }/products/import/shop`,
                    {
                        uploadType: "import",
                        file: productsImportFile,
                        docModel: capitalize(storedUserData.role) || "User",
                        ownerID: storedUserData.ID || "",
                    },
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Bearer ${token}`,
                        },
                    },
                )
                .then((result) => {
                    setIsLoading(false);
                    if (result.status === 201) {
                        successToast(__("File was successfully uploaded"));
                        setTimeout(() => window.location.reload(), 700);
                    }
                    if (result.status === 206) {
                        warnToast(result.data.message || "File was partially uploaded");
                        setPartiallyUpload({
                            failedProducts: result.data.data.failed,
                            total: result.data.data.total,
                            successful: result.data.data.successful
                        });
                    }
                })
                .catch((e) => {
                    setIsLoading(false);
                    const res = e.response.data;
                    errorToast(
                        __("Something went wrong. ") + res.message,
                    );

                    if (res.data.failed) {
                        setPartiallyUpload({
                            message: res.message,
                            failedProducts: res.data.failed,
                            total: res.data.total,
                            successful: res.data.successful
                        });
                    }
                });
        }
    }, [productsImportFile]);

    let urlBase = `${config.API_BASE_URL}/businesses/${state?.business._id || businessID
        }/products?`;
    const [url, setUrl] = useState(urlBase);

    const { data, loading, error } = useApiHook(url);

    const dispatch = useDispatch();
    const controller = useSelector(
        (state) => state.paginationControllerReducer,
    );

    useEffect(() => {
        if (searchInputValue)
            setUrl(`${config.API_BASE_URL}/businesses/${state?.business._id || businessID
                }/products/search/products?query=${searchInputValue}`);
        else setUrl(urlBase);
    }, [searchInputValue]);

    useEffect(() => {
        if (data) {
            dispatch(updateTotalAction(parseInt(data?.data?.totalDocs) || 0));
        }
    }, [data]);

    useEffect(() => {
        if (data?.data?.limit === 200000) {
            const toBeExported = data?.data?.docs.map(product => {
                return ({
                    "Article": product?.article || "",
                    "Штрихкод": product?.productTasnif?.internationalCode || "",
                    "Наименование": product?.title?.ru,
                    "Единица": product?.packageCode || "",
                    "Количество": product?.inStock || "",
                    "Код ИКПУ": product?.productTasnif?.icps,
                    "Цена продажная": product?.price / 100,
                    "Цена покупки": product?.purchasePrice / 100 || 0,
                    "НДС %": product?.VAT || "",
                    "Скидка %": product?.discount?.value?.percentage || 0,
                    "Категория": product?.categories[0]?.title?.ru,
                    "Срок годности": "",
                    "Описание": product?.description?.ru,
                    "Комитент ИНН/ПИНФЛ": product?.committentTIN,
                    "Маркировка": product?.isMarked ? "Есть" : "Нет",
                });
            });
            exportToExcel(toBeExported, "products.xlsx");
            setUrl(
                `${urlBase}&page=${controller.page + 1}&limit=${controller.pageSize
                }`,
            );
        }
    }, [data?.data?.limit]);

    useEffect(() => {
        setUrl(
            `${urlBase}&page=${controller.page + 1}&limit=${controller.pageSize
            }`,
        );
    }, [controller.page, controller.pageSize]);

    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );
    if (error) return <div>{error}</div>;
    let products = [];
    let categories = "";

    if (data?.data?.docs)
        data?.data?.docs?.map((item) => {
            let product = {
                title: item.title?.uz || item.title?.ru || "—",
                productType: capitalize(item.productType) || "—",
                icps: item.productTasnif?.icps || "-",
                categories: (categories = item.categories.map(
                    (category, index) => {
                        return (
                            category.title?.ru +
                            (index === item.categories.length - 1 ? "" : ", ")
                        );
                    },
                )),
                price: item.price / 100 || 0,
                // discountAmount: item.discount.isActive ? (item.discount?.value?.amount) : 0,
                // discountPercentage: item.discount.isActive ? (item.discount?.value?.percentage) : 0,
                discountAmount: item.discount?.value?.amount / 100 || 0,
                discountPercentage: item.discount?.value?.percentage || 0,
                id: item._id,
                inStock: item.inStock,
                slug: item.slug || "",
                status: item.status,
                businessID: state?.business._id || businessID,
                businessSlug: state?.business.slug || businessID,
                vat: item.VAT,
                unit: item.units,
            };
            products.push(product);
        });

    const openModals = (id) => {
        setModalIsOpen(true);
        setId(id);
    };
    const closeModal = () => {
        setModalIsOpen(false);
    };
    const closeExportModal = () => {
        setExportModalOpen(false);
    };

    const removeProduct = () => {
        ApiService.deleteEvent(`/businesses/${state?.business?._id || businessID}/products/${id}`, token)
            .then((res) => {
                if (res.status === 200) {
                    window.location.reload();
                }
            })
            .catch((error) => {
                errorToast(error);
            });
    };

    const handleDeleteProducts = () => {
        if (checkedProducts.length > 0) {
            axios({
                method: "post",
                url: `${config.API_BASE_URL}/businesses/${state.business._id || businessID}/products/delete/all`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                data: checkedProducts
            }).then(() => {
                successToast(__("Successfully deleted"));
                window.location.reload();
            })
                .catch((error) => {
                    errorToast(__(error.message));
                });
        } else {
            errorToast(__("Please check some product to delete"));
        }
    };

    const columns = [
        {
            field: "title",
            headerName: __("Title"),
            flex: 2,
            cellClassName: "main-column-cell",
            renderCell: ({ row }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent={"flex-start"}
                        width={"100%"}
                        onClick={() => {
                            navigate(
                                `/businesses/${state?.business?.slug ||
                                state?.business?._id ||
                                businessID
                                }/products/${row.slug || row.id}`,
                                {
                                    state: {
                                        business: {
                                            id:
                                                state?.business._id ||
                                                businessID,
                                            slug:
                                                state?.business.slug ||
                                                businessID,
                                        },
                                        product: {
                                            id: row.id,
                                            slug: row.slug,
                                        },
                                    },
                                },
                            );
                        }}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={500}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {row.title}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "productType",
            headerName: __("Type"),
            className: "table-phone",
            flex: 1,
            renderCell: ({ row: { productType } }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {__(productType)}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "icps",
            headerName: __("ICPS"),
            flex: 1.5,
            cellClassName: "mane-column--cell",
            renderCell: ({ row }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {row.icps}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "categories",
            headerName: __("Categories"),
            flex: 1.5,
            cellClassName: "wrapped-cell",
            renderCell: ({ row }) => {
                return (
                    <Typography
                        fontFamily="Manrope, sans-serif"
                        fontWeight={500}
                        fontSize={14}
                        color={colors.common["black"]}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                        whiteSpace={"nowrap"}
                    >
                        {row.categories}
                    </Typography>
                );
            },
        },
        {
            field: "price",
            headerName: __("Price"),
            flex: 0,
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <NumericFormat
                            displayType="text"
                            thousandSeparator={" "}
                            decimalSeparator={","}
                            value={row.price}
                            decimalScale={2}
                            fixedDecimalScale={true}
                        />
                    </Box>
                );
            },
        },
        {
            field: "vat",
            headerName: __("VAT"),
            flex: 0,
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={400}
                                fontSize={14}
                                color={colors.common["black"]}
                            >
                                {row.vat ? row.vat + "%" : 0}
                            </Typography>
                    </Box>
                );
            },
        },
        {
            field: "units",
            headerName: __("Unit"),
            flex: 1.5,
            cellClassName: "wrapped-cell",
            renderCell: ({ row }) => {
                return (
                    <Typography
                        fontFamily="Manrope, sans-serif"
                        fontWeight={500}
                        fontSize={14}
                        color={colors.common["black"]}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                        whiteSpace={"nowrap"}
                    >
                        {row.unit}
                    </Typography>
                );
            },
        },
        {
            field: "inStock",
            headerName: __("In Stock"),
            flex: 0,
            renderCell: ({ row }) => {
                return (
                  <Box display="flex" justifyContent="center">
                      <NumericFormat
                        displayType="text"
                        thousandSeparator={" "}
                        value={row.inStock}
                        fixedDecimalScale={true}
                      />
                  </Box>
                );
            },
        },
        {
            field: "action",
            headerName: __("Action"),
            type: "string",
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Action
                            onClick={() =>
                                navigate(
                                    `/businesses/${state?.business?.slug ||
                                    state?.business?._id ||
                                    businessID
                                    }/products/${row.slug || row.id}/edit`,
                                    {
                                        state: {
                                            business: {
                                                id:
                                                    state?.business._id ||
                                                    businessID,
                                                slug:
                                                    state?.business.slug ||
                                                    businessID,
                                                name: state?.business.name,
                                            },
                                            product: {
                                                id: row.id,
                                                slug: row.slug,
                                            },
                                        },
                                    },
                                )
                            }
                            onClickView={() =>
                                navigate(
                                    `/businesses/${state?.business?.slug ||
                                    state?.business?._id ||
                                    businessID
                                    }/products/${row.slug || row.id}`,
                                    {
                                        state: {
                                            business: {
                                                id:
                                                    state?.business._id ||
                                                    businessID,
                                                slug:
                                                    state?.business.slug ||
                                                    businessID,
                                                name: state?.business.name
                                            },
                                            product: {
                                                id: row.id,
                                                slug: row.slug,
                                            },
                                        },
                                    },
                                )
                            }
                            openModal={() => openModals(row?.id)}
                        />
                    </Box>
                );
            },
        },
    ];

    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Businesses", link: "/businesses" },
                    { label: "Products" },
                ]}
            />
            <div className="page page-table-branch">
                <Box>
                    <Filter
                        search={searchInputValue}
                        setSearch={setSearchInputValue}
                        description={__("Products")}
                        buttons={[
                            {
                                text: __("Delete"),
                                onClick: handleDeleteProducts,
                                classNames: "outline-error"
                            },
                            {
                                text: __("Import Products"),
                                onClick: () => {
                                    setImportModalOpen(true);
                                },
                            },
                            {
                                text: __("Export Products"),
                                onClick: () => {
                                    setExportModalOpen(true);
                                },
                            },
                            {
                                text: __("Add Product"),
                                link: `/businesses/${state?.business.slug ||
                                    state?.business._id ||
                                    businessID
                                    }/products/create`,
                                state: {
                                    business: state?.business,
                                },
                                classNames: "outline",
                            },
                        ]}
                        filters={filters}
                    />
                    <Box
                        m="40px 0 0"
                        height="75vh"
                        sx={{
                            "&": {
                                margin: "0",
                                height: "90vh",
                                minHeight: "350px",
                            },
                            "& .name-column--cell": {
                                color: colors.green[300],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                            },
                        }}
                    >
                        <DataGrid
                            rows={products}
                            columns={columns}
                            rowCount={controller.total}
                            page={controller.page}
                            pageSize={controller.pageSize}
                            {...config.TABLE_CONFIG(__)}
                            checkboxSelection
                            onSelectionModelChange={(ids) => {
                                const selectedIDs = new Set(ids);
                                const selectedRowData = products.filter((row) => selectedIDs.has(row.id.toString()));
                                setCheckedProducts(selectedRowData);
                            }}
                            localeText={getLocaleText(__)}
                        />
                    </Box>
                </Box>
                {/* ======= MODALS ======= */}
                <Modal
                    className="delete-modal"
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                >
                    <div className="modal-bottom">
                        <ModalWindow
                            title="Are you sure?"
                            text="Are you sure you want to permanently delete this product?"
                            onClick={closeModal}
                            onClickDelete={removeProduct}
                        />
                    </div>
                </Modal>

                <Modal
                    className="delete-modal"
                    isOpen={importModalOpen}
                    onRequestClose={closeModal}
                >
                    <div className="modal-bottom">
                        <div className="component-modal-window">
                            <div className="modal-inner">
                                <div className="modal-title">
                                    <h1 className="modal-window-title">
                                        {__("Import of the Product")}
                                    </h1>
                                </div>
                                <div
                                    className="close-icon"
                                    onClick={() => {
                                        setImportModalOpen(false);
                                    }}
                                ></div>
                                <div className="modal-body">
                                    <div className="window-modal-text">
                                        <p className="window-text">
                                            {__(
                                                "Download the example of the Excel file for understanding the format of data to be imported",
                                            )}
                                        </p>
                                    </div>
                                    <div className="modal-file">
                                        <div className="file">
                                            <span className="file-icon"></span>
                                            <div className="file-text">
                                                <p className="text">
                                                    Example.xlsx
                                                </p>
                                                <p className="text-size">
                                                    10KB
                                                </p>
                                            </div>
                                        </div>

                                        <a
                                            href={`${config.API_HOST}/uploads/templates/ProductsImportTemplate.xlsx`}
                                            download="sample.xlsx"
                                            className="download-link"
                                        >
                                            <i className="icon-download"></i>
                                        </a>
                                    </div>
                                </div>
                                <div className="btn-container">
                                    <Button
                                        className="btn outline sm"
                                        text={__("Cancel")}
                                        onClick={() =>
                                            setImportModalOpen(false)
                                        }
                                    ></Button>
                                    <Button
                                        className="btn sm"
                                        text={__("Continue")}
                                        onClick={() => {
                                            setImportModalOpen(false);
                                            setIsDragNDropOpen(true);
                                        }}
                                    ></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    className="delete-modal"
                    isOpen={exportModalOpen}
                    onRequestClose={closeExportModal}
                >
                    <div className="modal-bottom">
                        <div className="component-modal-window">
                            <div className="modal-inner">
                                <div className="modal-title">
                                    <h1 className="modal-window-title">
                                        {__("Export of the Products")}
                                    </h1>
                                </div>
                                <div
                                    className="close-icon"
                                    onClick={() => {
                                        setExportModalOpen(false);
                                    }}
                                ></div>
                                <div className="modal-body">
                                    <div className="window-modal-text">
                                        <p className="window-text">
                                            {__(
                                                "Are you sure you want to export your products into Excel file?",
                                            )}
                                        </p>
                                    </div>
                                </div>
                                <div className="btn-container">
                                    <Button
                                        className="btn outline sm"
                                        text={__("Cancel")}
                                        onClick={() =>
                                            setExportModalOpen(false)
                                        }
                                    ></Button>
                                    <Button
                                        className="btn sm"
                                        text={__("Continue")}
                                        onClick={() => {
                                            setExportModalOpen(false);
                                            setUrl(`${config.API_BASE_URL}/businesses/${state?.business._id || businessID}/products?limit=200000`);
                                        }}
                                    ></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                {/* ===== Drag&Drops ===== */}
                {shopDragNDropOpen && (
                    <DragAndDrop
                        setter={(file) => setShopImportFile(file)}
                        closeDragNDrop={() => setShopDragNDropOpen(!shopDragNDropOpen)}
                        isUpload={false}
                        isXLS={true}
                        isLoading={isLoading}
                        partiallyUpload={partiallyUpload}
                        setPartiallyUpload={setPartiallyUpload}
                    />
                )}

                {isDragNDropOpen && (
                    <DragAndDrop
                        setter={handleImportFile}
                        closeDragNDrop={handleDragNDropOpen}
                        isUpload={false}
                        isXLS={true}
                        isLoading={isLoading}
                        partiallyUpload={partiallyUpload}
                        setPartiallyUpload={setPartiallyUpload}
                    />
                )}
            </div>
        </div>
    );
};

export default PageProductTable;
