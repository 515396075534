import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {NumericFormat} from "react-number-format";
import {Box, colors, Typography} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import BreadCrumbs from "../../../components/BreadCrumbs";
import Filter from "../../../components/Filter";
import Preloader from "../../../components/Preloader";

import {updateTotalAction} from "../../../redux/reducers/paginationControllerReducer";
import {translate} from "../../../translations/translate";
import {getParsedDate} from "../../../utils/getParsedDate";
import useApiHook from "../../../hooks/useApiHook";
import config from "../../../config";
import "./styles.sass";
import { resetFilters } from "../../../redux/actions/filtersAction";

const breadCrumbsArray = [
  { label: "Arrivals" },
];

const PageArrivalsTable = () => {
    const dispatch = useDispatch();
    // Lang selector
    const lang = useSelector((state) => state.languageReducer.lang);
    // Pagination controller
    const controller = useSelector((state) => state.paginationControllerReducer);
    const filterState = useSelector((state) => state.filter);
    // state and businessID from react-router-dom
    const { state, pathname } = useLocation();
    const { businessID } = useParams();

    const { from, to } = filterState.expiresAt;
    const filterByDate = `${from ? `&fromDate=${from}` : ""}${to ? `&toDate=${to}` : ""}`;
    const baseUrl = `${config.API_BASE_URL}/businesses/${
        businessID || state?.business?._id}/invoice/get?operationType=Income`;

    // States
    const [url, setUrl] = useState(baseUrl);

    const {data, loading} = useApiHook(url);

    // functions / handlers
    const __ = (key) => translate(lang, key);

    const formatArrivalsArr = (arrivals) => {
        return arrivals?.map((arrival) => ({
            ...arrival,
            id: arrival._id
        }));
    };

    // Effects
    useEffect(() => {
        setUrl(
            `${baseUrl}&page=${controller.page + 1}&limit=${controller.pageSize}${filterByDate}`
        );
    }, [controller.page, controller.pageSize]);

    useEffect(() => {
        if (data) {
            dispatch(updateTotalAction(parseInt(data?.data?.totalDocs) || 0));
        }
    }, [data]);

    useEffect(() => {
        setUrl(baseUrl + filterByDate);
    }, [filterByDate]);

    useEffect(() => {
        return () => {
            dispatch(resetFilters());
        };
    }, [pathname]);

    const productColumn = [
        {
            field: "invoiceNum",
            headerName: __("Invoice Number"),
            // width: 250,
            flex: 1,
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={600}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                  >
                      <Link
                        to={`/businesses/${state?.business?._id}/arrivals/${row?.id}`}
                        state={{
                            business: {
                                id: state?.business._id || businessID,
                                slug: state?.business.slug
                            }
                        }}
                      >
                          {row?.invoiceNumber}
                      </Link>
                  </Typography>
                );
            }
        },
        {
            field: "date",
            headerName: __("Date"),
            // width: 155,
            flex: 1,
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                  >
                  {getParsedDate(row?.recieptDate)}
                  </Typography>
                );
            }
        },
        {
            field: "numberOfItems",
            headerName: __("Number of Items"),
            // width: 135,
            flex: 1,
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                  >
                      {row?.totalCount}
                  </Typography>
                );
            }
        },
        {
            field: "totalSum",
            headerName: __("Total Sum"),
            // width: 135,
            flex: 1,
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                  >
                      <NumericFormat
                        displayType="text"
                        value={row?.totalPrice}
                        thousandSeparator={" "}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale
                      />
                  </Typography>
                );
            }
        },
        {
            field: "branchOffice",
            headerName: __("Branch"),
            // width: 135,
            flex: 1,
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                  >
                      {row?.toBranchID?.name}
                  </Typography>
                );
            }
        },
        {
            field: "resPerson",
            headerName: __("Responsible Person"),
            // width: 138,
            flex: 1,
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                  >
                      {row?.responsiblePerson}
                  </Typography>
                );
            }
        }
    ];
    const tableRow = formatArrivalsArr(data?.data?.docs) || [];

    if (loading) return <Preloader />;

    return (
      <div className="page-withdrawal-table">
          <BreadCrumbs breadcrumbs={breadCrumbsArray}/>
          <div className="page-table-branch">
              <Box>
                  <Filter
                    description={__("Arrivals")}
                    createLink="create"
                    text={__("Add Arrival")}
                    state={state}
                    filters={{isExpiresAt: true, dateHeading: "Date"}}
                  />
                  <Box
                    sx={{
                        "&": {
                            margin: "0",
                            width: "100%"
                        },
                        "& .name-column--cell": {
                            color: colors.green[300],
                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                        },
                    }}
                  >
                      <DataGrid
                        rows={tableRow}
                        rowCount={controller.total}
                        page={controller.page}
                        pageSize={controller.pageSize}
                        columns={productColumn}
                        {...config.TABLE_CONFIG(__)}
                      />
                  </Box>
              </Box>
          </div>
      </div>
    );
};

export default PageArrivalsTable;
