import { useLocation, useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { translate } from "../../../translations/translate";
import PageHeader from "../../../components/PageHeader";
import config from "../../../config";
import useApiHook from "../../../hooks/useApiHook";
import Preloader from "../../../components/Preloader";
import BreadCrumbs from "../../../components/BreadCrumbs";
import "./styles.sass";
import { getParsedDate } from "../../../utils/getParsedDate";
import noImage from "../../../assets/images/no-image.png";
import { Box, Typography, colors } from "@mui/material";
import { Link } from "react-router-dom";
import StatusTag from "../../../components/StatusTag";
import Action from "../../../components/Action";
import Filter from "../../../components/Filter";
import { DataGrid } from "@mui/x-data-grid";
import {url as genURL} from "../../../utils/generalizeURL";

const PageClientView = () => {
    const { state } = useLocation();
    const { id } = useParams();
    const url = `${config.API_BASE_URL}/clients/${state?.client?.id || id}`;
    const { data, loading, error } = useApiHook(url);
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    const client = data?.data?.docs[0];
    const passport = client?.passportDoc;
    const dealerFullName = client?.dealerID ?  client.dealerID.name.first + " " + client.dealerID.name.last : "";
    const buttons = [
        {
            name: "Back",
            link: "/clients",
            state: {},
            className: "outline"
        },
        {
            name: "Edit",
            link: `/clients/${state?.client?.id || id}/edit`,
            state: {
                client: {
                    id: state?.client?.id || id
                }
            },
            className: ""
        }
    ];

    // BUSINESSES LOGIC
    const navigate = useNavigate();
    let businessList = [];

    client?.businesses.map((item) => {
        let businessSingle = {
            businessName: item.name || "—",
            id: item._id,
            status: item.status,
        };
        businessList.push(businessSingle);
    });

    const columns = [
        {
            field: "businessName",
            headerName: __("Name"),
            flex: 2.2,
            cellClassName: "mane-column--cell",
            renderCell: ({ row, row: { businessName } }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                    >
                        <Link
                            to={`/businesses/${row.id}`}
                            state={{
                                business: {
                                    id: row.id,
                                },
                            }}
                            className="link"
                        >
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={600}
                                fontSize={14}
                                color={colors.common["black"]}
                                textOverflow={"ellipsis"}
                                overflow={"hidden"}
                                whiteSpace={"nowrap"}
                            >
                                {businessName}
                            </Typography>
                        </Link>
                    </Box>
                );
            },
        },
        {
            field: "status",
            headerName: __("Status"),
            type: "string",
            flex: 1.5,
            renderCell: (props) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <StatusTag className={"sm"} text={props.value} />
                    </Box>
                );
            },
        },
        {
            field: "action",
            headerName: __("Action"),
            type: "string",
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Action
                            edit={false}
                            deleteAction={false}
                            onClick={() =>
                                navigate(
                                    `/businesses/${row.slug || row.id}/edit`,
                                    {
                                        state: {
                                            business: {
                                                id: row.id,
                                                slug: row.slug,
                                            },
                                        },
                                    },
                                )
                            }
                            onClickView={() =>
                                navigate(`/businesses/${row.slug || row.id}`, {
                                    state: {
                                        business: {
                                            id: row.id,
                                            slug: row.slug,
                                        },
                                    },
                                })
                            }
                        />
                    </Box>
                );
            },
        },
    ];

    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );
    if (error) return <div>{error}</div>;

    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Client", link: "/clients" },
                    { label: "View" },
                ]}
            />
            <div className="page component-page-client-view">
                <form className="page-inner">
                    <PageHeader
                        name={__("View Client")}
                        buttons={buttons}
                    />
                    <div className="cards">
                        <div className="left-side-cards">
                            {data?.data?.docs?.map((item, i) => (
                                <div key={i}>
                                    <div className="component-box-card">
                                        <div className="card-header">
                                            <div className="card-info">
                                                <div className="card-icon">
                                                    <i className="icon-profile" />
                                                </div>
                                                <div className="card-text">
                                                    <p className="card-title">
                                                        {__(
                                                            "Client Information",
                                                        )}
                                                    </p>
                                                    <p className="card-subtitle" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-column">
                                            <div className="card-avatar">
                                                <img
                                                    className="avatar-img"

                                                    src={client?.avatar?.fileInfo?.url
                                                        ? config.API_HOST + "/" + client?.avatar?.fileInfo?.url
                                                        : noImage}
                                                    alt="avatar-img"
                                                />
                                            </div>
                                            <div className="input-row">
                                                <div className="input-item">
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                        >
                                                            <input
                                                                defaultValue={
                                                                    item?.name
                                                                        ?.first
                                                                }
                                                                className="input"
                                                                type="text"
                                                                disabled
                                                            />
                                                            <span className="input-text">
                                                                {__(
                                                                    "First Name",
                                                                )}
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                        >
                                                            <input
                                                                defaultValue={
                                                                    item?.name
                                                                        ?.last
                                                                }
                                                                className="input"
                                                                type="text"
                                                                disabled
                                                            />
                                                            <span className="input-text">
                                                                {__(
                                                                    "Last Name",
                                                                )}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="input-item">
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                        >
                                                            <input
                                                                defaultValue={
                                                                    item?.name
                                                                        ?.middle
                                                                }
                                                                className="input"
                                                                type="text"
                                                                disabled
                                                            />
                                                            <span className="input-text">
                                                                {__(
                                                                    "Middle Name",
                                                                )}
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                        >
                                                            <input
                                                                defaultValue={getParsedDate(item?.birthDate)}
                                                                className="input"
                                                                type="tel"
                                                                disabled
                                                            />
                                                            <span className="input-text date-picker">
                                                                {__(
                                                                    "Date of Birth",
                                                                )}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="input-item">
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                        >
                                                            <input
                                                                defaultValue={
                                                                    item?.phone
                                                                }
                                                                className="input"
                                                                type="tel"
                                                                disabled
                                                            />
                                                            <span className="input-text">
                                                                {__("Phone")}
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                        >
                                                            <input
                                                                defaultValue={
                                                                    item?.email
                                                                }
                                                                className="input"
                                                                type="email"
                                                                disabled
                                                            />
                                                            <span className="input-text">
                                                                {__("Email")}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="component-box-card">
                                        <div className="card-header">
                                            <div className="card-info">
                                                <div className="card-icon">
                                                    <i className="icon-information" />
                                                </div>
                                                <div className="card-text">
                                                    <p className="card-title">
                                                        {__(
                                                            "Passport Information",
                                                        )}
                                                    </p>
                                                    <p className="card-subtitle"></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-item input-item-wrap">
                                            <div className="component-input">
                                                <div className="input-label">
                                                    <span className="input-text">
                                                        {__("Client's Passport Scan")}
                                                    </span>
                                                    <div
                                                      className="input input-disabled input-flex">
                                                        <div className="file">
                                                            {passport ? (
                                                              <>
                                                                  <img
                                                                    src={`/fileExtensions/${passport?.fileInfo?.MIMEType.split("/")[1] || "fads"}.svg`}
                                                                    onError={(e) => e.target.src = "/fileExtensions/default.svg"}
                                                                    alt=""
                                                                    className="icon"
                                                                  />
                                                                  <p className="file__text">{passport?.fileInfo?.originalName}</p>
                                                              </>
                                                            ) : (
                                                              <div className="icon"></div>
                                                            )}
                                                        </div>
                                                        {genURL(passport?.fileInfo?.url) &&
                                                          <div className="action">
                                                              <a className="icon"
                                                                 href={genURL(passport?.fileInfo?.url)}
                                                                 target="_blank"
                                                                 rel="noreferrer"
                                                              >
                                                                  <i className="icon-view"/>
                                                              </a>
                                                          </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {dealerFullName && (
                                              <div className="component-input">
                                                  <label
                                                    className="input-label"
                                                  >
                                                      <input
                                                        value={dealerFullName}
                                                        className="input"
                                                        type="text"
                                                        disabled
                                                      />
                                                      <span className="input-text">
                                                        {__("Dealer")}
                                                    </span>
                                                  </label>
                                              </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="page page-business-table businesses-table">
                                        <Box>
                                            <Filter
                                              description={__("Businesses")}
                                              text={__("Add Business")}
                                              createLink="/businesses/create"
                                              filters={{
                                                  isBusinessType: true,
                                                  isStatus: true,
                                              }}
                                              page="businesses"
                                            />
                                            <Box
                                              sx={{
                                                  "&": {
                                                      margin: "0",
                                                      width: "100%",
                                                      height: "90vh",
                                                      minHeight: "350px",
                                                  },
                                                  "& .name-column--cell": {
                                                      color: colors.green[300],
                                                  },
                                                  "& .MuiDataGrid-footerContainer": {
                                                      borderTop: "none",
                                                  },
                                              }}
                                            >
                                                <DataGrid
                                                  rows={businessList}
                                                  columns={columns}
                                                  {...config.TABLE_CONFIG(__)}
                                                />
                                            </Box>
                                        </Box>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageClientView;
