import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import useApiHook from "../../../hooks/useApiHook";

import {Box, colors, Typography} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import BreadCrumbs from "../../../components/BreadCrumbs";
import Filter from "../../../components/Filter";
import Preloader from "../../../components/Preloader";
import {NumericFormat} from "react-number-format";

import {updateTotalAction} from "../../../redux/reducers/paginationControllerReducer";
import {translate} from "../../../translations/translate";
import config from "../../../config";
import { exportToExcel } from "../../../utils/exportToExcel";
import Modal from "react-modal";
import Button from "../../../components/global/Button";

const BranchWarehouseTable = () => {
    const dispatch = useDispatch();
    // Lang selector
    const lang = useSelector((state) => state.languageReducer.lang);
    // Pagination controller
    const controller = useSelector((state) => state.paginationControllerReducer);
    // state and businessID from react-router-dom
    const { state } = useLocation();
    const { businessID, branchID } = useParams();
    const navigate = useNavigate();
    const [exportModalOpen, setExportModalOpen] = useState(false);

    const baseUrl = `${config.API_BASE_URL}/businesses/${businessID || state?.business?._id}/products/branch/${branchID}?`;
    // States
    const [ url, setUrl] = useState(`${config.API_BASE_URL}/businesses/${businessID || state?.business?._id}/products/branch/${branchID}?`);
    const { loading, data } = useApiHook(url);
    // functions / handlers
    const __ = (key) => translate(lang, key);

    const formatProductsArr = (products) => {
        return products?.map((product) => ({
            ...product,
            id: product._id
        }));
    };

    // Effects
    useEffect(() => {
        setUrl(
            `${baseUrl}&page=${controller.page + 1}&limit=${controller.pageSize}`
        );
    }, [controller.page, controller.pageSize]);

    useEffect(() => {
        if (data) {
            dispatch(updateTotalAction(parseInt(data?.data?.totalDocs) || 0));
        }
    }, [data]);

    // This effect need to update fetch url on route change
    useEffect(() => {
        setUrl(baseUrl);
    }, [branchID]);

    // Constants related table row and column also filter buttons and breadcrumbs
    const productColumn = [
        {
            field: "title",
            headerName: __("Title"),
            flex: 2,
            cellClassName: "main-column-cell",
            renderCell: ({row}) => {
                return (
                    <Box
                        display="flex"
                        justifyContent={"flex-start"}
                        width={"100%"}
                        onClick={() => {
                            navigate(
                                `/businesses/${state?.business?.slug ||
                                state?.business?._id ||
                                businessID
                                }/products/${row.slug || row.id}`,
                                {
                                    state: {
                                        business: {
                                            id:
                                                state?.business._id ||
                                                businessID,
                                            slug:
                                                state?.business.slug ||
                                                businessID,
                                        },
                                        product: {
                                            id: row.id,
                                            slug: row.slug,
                                        },
                                    },
                                },
                            );
                        }}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={600}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {row?.title?.uz}
                        </Typography>
                    </Box>
                );
            }
        },
        {
            field: "article",
            headerName: __("Article"),
            flex: 1,
            renderCell: ({row}) => {
                return (
                    <Typography
                        fontFamily="Manrope, sans-serif"
                        fontWeight={400}
                        fontSize={14}
                        color={colors.common["black"]}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                        whiteSpace={"nowrap"}
                    >
                        {row?.article}
                    </Typography>
                );
            }
        },
        {
            field: "icps",
            headerName: __("ICPS"),
            flex: 1,
            renderCell: ({row}) => {
                return (
                    <Typography
                        fontFamily="Manrope, sans-serif"
                        fontWeight={400}
                        fontSize={14}
                        color={colors.common["black"]}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                        whiteSpace={"nowrap"}
                    >
                        {row?.productTasnif?.icps}
                    </Typography>
                );
            }
        },
        {
            field: "sellingPrice",
            headerName: __("Selling Price"),
            flex: 1,
            renderCell: ({row}) => {
                return (
                    <Typography
                        fontFamily="Manrope, sans-serif"
                        fontWeight={400}
                        fontSize={14}
                        color={colors.common["black"]}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                        whiteSpace={"nowrap"}
                    >
                        <NumericFormat
                            displayType="text"
                            value={row?.price / 100}
                            thousandSeparator={" "}
                            decimalSeparator={","}
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    </Typography>
                );
            }
        },
        {
            field: "branchCount",
            flex: 1,
            headerName: __("Branch Count"),
            renderCell: ({row}) => {
                return (
                    <Typography
                        fontFamily="Manrope, sans-serif"
                        fontWeight={400}
                        fontSize={14}
                        color={colors.common["black"]}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                        whiteSpace={"nowrap"}
                    >
                        {row?.stock?.find(item => item?.branchID?._id === branchID)?.count.available}
                    </Typography>
                );
            }
        },
        {
            field: "inStock",
            flex: 1,
            headerName: __("In Stock"),
            renderCell: ({row}) => {
                return (
                    <Typography
                        fontFamily="Manrope, sans-serif"
                        fontWeight={400}
                        fontSize={14}
                        color={colors.common["black"]}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                        whiteSpace={"nowrap"}
                    >
                        {row.inStock}
                    </Typography>
                );
            }
        },
    ];
    const tableRow = formatProductsArr(data?.data?.docs) || [];
    const breadCrumbsArray = [
        { label: "Warehouse", link: `/businesses/${businessID || state?.business?._id}/warehouse` },
        { label: state?.branchName},
    ];
    const buttons = [
        {
            text: __("Add Arrival"),
            link: `/businesses/${businessID}/arrivals/create`,
            state: state,
        },
        {
            text: __("Export Products"),
            onClick: () => {
                setExportModalOpen(true);
            },
        }
    ];
    // EXPORT
    useEffect(() => {
        if (data?.data?.limit === 200000) {
            const toBeExported = data?.data?.docs.map(product => {
                return ({
                    "Article": product?.article || "",
                    "Штрихкод": product?.productTasnif?.internationalCode || "",
                    "Наименование": product?.title?.ru,
                    "Единица": product?.packageCode || "",
                    "Количество": product?.stock?.find(item => item?.branchID?._id === branchID)?.count.available || "",
                    "Код ИКПУ": product?.productTasnif?.icps,
                    "Цена продажная": product?.price / 100,
                    "Цена покупки": product?.purchasePrice / 100 || 0,
                    "НДС %": product?.VAT || "",
                    "Скидка %": product?.discount?.value?.percentage || 0,
                    "Категория": product?.categories[0]?.title?.ru,
                    "Срок годности": "",
                    "Описание": product?.description?.ru,
                    "Комитент ИНН/ПИНФЛ": product?.committentTIN,
                    "Маркировка": product?.isMarked ? "Есть" : "Нет",
                });
            });
            exportToExcel(toBeExported, "products.xlsx");
            setUrl(
                `${url}&page=${controller.page + 1}&limit=${controller.pageSize
                }`,
            );
        }
    }, [data?.data?.limit]);
    const closeExportModal = () => {
        setExportModalOpen(false);
    };
    if (loading) return <Preloader />;

    return (
        <div className="page-withdrawal-table">
            <BreadCrumbs breadcrumbs={breadCrumbsArray}/>
            <div className="page-table-branch">
                <Box>
                    <Filter
                        description={state?.branchName}
                        buttons={buttons}
                        // filters={filters}
                        // page={page}
                        // search={searchInputValue}
                        // setSearch={setSearchInputValue}
                    />
                    <Box
                        sx={{
                            "&": {
                                margin: "0",
                                width: "100%"
                            },
                            "& .name-column--cell": {
                                color: colors.green[300],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                            },
                        }}
                    >
                        <DataGrid
                            rows={tableRow}
                            rowCount={controller.total}
                            page={controller.page}
                            pageSize={controller.pageSize}
                            columns={productColumn}
                            {...config.TABLE_CONFIG(__)}
                        />
                    </Box>
                </Box>
                <Modal
                    className="delete-modal"
                    isOpen={exportModalOpen}
                    onRequestClose={closeExportModal}
                >
                    <div className="modal-bottom">
                        <div className="component-modal-window">
                            <div className="modal-inner">
                                <div className="modal-title">
                                    <h1 className="modal-window-title">
                                        {__("Export of the Products")}
                                    </h1>
                                </div>
                                <div
                                    className="close-icon"
                                    onClick={() => {
                                        setExportModalOpen(false);
                                    }}
                                ></div>
                                <div className="modal-body">
                                    <div className="window-modal-text">
                                        <p className="window-text">
                                            {__(
                                                "Are you sure you want to export your products into Excel file?",
                                            )}
                                        </p>
                                    </div>
                                </div>
                                <div className="btn-container">
                                    <Button
                                        className="btn outline sm"
                                        text={__("Cancel")}
                                        onClick={() =>
                                            setExportModalOpen(false)
                                        }
                                    ></Button>
                                    <Button
                                        className="btn sm"
                                        text={__("Continue")}
                                        onClick={() => {
                                            setExportModalOpen(false);
                                            setUrl(`${config.API_BASE_URL}/businesses/${state?.business._id || businessID}/products/branch/${branchID}/?limit=200000`);
                                        }}
                                    ></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default BranchWarehouseTable;
