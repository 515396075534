// React libraries
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

// Components
import Button from "../global/Button";

// Styles
import "./styles.sass";

// utils
import { translate } from "../../translations/translate";


const Input = ({ className, type, text, updatePassword, isClientPassword }) => {

    // Language
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);


    const [value, setValue] = useState("");
    const [inputType, setInputType] = useState("");

    const [iconClassName, setIconClassName] = useState("icon-view");
    const isPassword = type === "password";

    useEffect(() => {
        setInputType(type);
    }, []);

    const generatorPassword = () => {
            const string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$";
            let genPassword = "";

            // Generate a random index for the first character (should be an alphabetic character)
            let charIndex = Math.floor(Math.random() * 52);
            genPassword += string[charIndex];

            // Generate a random index for the second character (should be a digit)
            charIndex = Math.floor(Math.random() * 10) + 52;
            genPassword += string[charIndex];

            // Generate the rest of the password
            for (let index = 3; index <= 8; index++) {
                charIndex = Math.floor(Math.random() * string.length);
                genPassword += string[charIndex];
            }
            setValue(genPassword);
            updatePassword(genPassword);
    };

    const handleIconClick = () => {
        setInputType((prev) => (prev === "password" ? "text" : "password"));
        setIconClassName((prev) =>
            prev === "icon-view" ? "icon-closed-eye" : "icon-view",
        );
    };

    const cpyFunc = () => {
        navigator.clipboard.writeText(value);
    };

    // Layout
    return (
        <div className="component-ui-input">
            <label className="ui-card-label">
                <span>{text}</span>
                <input
                    required
                    type={inputType}
                    value={value}
                    minLength={isPassword && 8}
                    autoComplete="new-password"
                    className={className}
                    onChange={(e) => {
                        setValue(e.target.value);

                        if (isPassword) {
                            updatePassword(e.target.value);
                        }
                    }}
                    onInvalid={e => e.target.setCustomValidity(__(text))}
                    onInput={e => e.target.setCustomValidity("")}
                />

                <div className="icon-container">
                    <i
                        onClick={handleIconClick}
                        className={`${iconClassName}`}
                    />
                </div>
            </label>

            {isPassword && value.length > 7 && (
                <Button className="button-copy" type="button" onClick={cpyFunc}>
                    <i className="icon-copy"></i>
                </Button>
            )}
            {isPassword && (
                <div className="button-password-generator">
                    <Button
                        type="button"
                        text={__("Generate")}
                        onClick={generatorPassword}
                        className="btn sm"
                    />
                </div>
            )}
        </div>
    );
};
Input.propTypes = {
    type: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.string,
};
export default Input;
