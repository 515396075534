import { useLocation, useParams } from "react-router";
import useApiHook from "../../../hooks/useApiHook";
import { useSelector } from "react-redux";
import { translate } from "../../../translations/translate";
import config from "../../../config";
import Preloader from "../../../components/Preloader";
import BreadCrumbs from "../../../components/BreadCrumbs";
import PageHeader from "../../../components/PageHeader";
import { formatDate } from "../../../utils/formatDate";
import { useState } from "react";
import { errorToast } from "../../../utils/toast";
import { useNavigate } from "react-router-dom";
import ZReportChequeTable from "../../../components/ZReportChequeTable";
import { NumericFormat } from "react-number-format";

const ZReportComponentView = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { state } = useLocation();
    const { token } = useSelector((state) => state.authReducer);
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    const [isExporting, setIsExporting] = useState(false);
    const url = `${config.API_BASE_URL}/businesses/${state?.businessID}/reports/${state?.reportID}`;
    const { data, loading, error } = useApiHook(url);
    const cashdesk = data?.data?.docs?.[0];

    const exportToPdf = async () => {
        setIsExporting(true);

        try {
            const response = await fetch(`${config.API_BASE_URL}/businesses/${cashdesk?.cashdeskID?.businessID}/reports/export/pdf/${cashdesk?._id}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error("Failed to download PDF");
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            // Создаем ссылку для скачивания файла
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Receipt.pdf");
            document.body.appendChild(link);
            link.click();
            // Очищаем созданный URL
            window.URL.revokeObjectURL(url);
        } catch (e) {
            errorToast(e);
            console.error(e);
        } finally {
            setIsExporting(false);
        }
    };

    if (loading) {
        return (
            <div>
                <Preloader />
            </div>
        );
    }

    if (error) return <div>{error}</div>;
    
    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Reports", link: `/businesses/${state?.businessID}/reports` },
                    { label: "Report ID" },
                ]}
            />
            <div className="page component-page-cashdesk-view ">
                <form className="page-inner">
                    <PageHeader 
                        key="receipt-header"
                        name={`${__("Report Info")}`}
                        text={__("Report Info")} link="/cheque/table"
                        buttons={[
                            {
                                name: __("Back"),
                                onClick: () => navigate(-1), 
                                className: "outline", 
                            },
                            {
                                name: __("Print"),
                                onClick: exportToPdf,
                                className: "blue",
                                disabled: isExporting
                            }
                        ]}
                    />
                    <div className="cards">
                        <div className="left-side-cards">
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Report Info")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>

                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                defaultValue={params?.reportID || "-"}
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Current Report Number")}
                                            </span>
                                        </label>
                                    </div>

                                    <div className="component-input">
                                        <label className="input-label">
                                            <input
                                                defaultValue={cashdesk?.fiscalModuleNumber || "-"}
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Fiscal Module Number")}
                                            </span>
                                        </label>
                                    </div>
                                </div>

                                <div className="input-item">
                                    <div className="component-input">
                                        <label className="input-label">
                                            <input
                                                defaultValue={cashdesk?.openedAt ? formatDate(cashdesk?.openedAt) : "—"}
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Opened At")}
                                            </span>
                                        </label>
                                    </div>

                                    <div className="component-input">
                                        <label className="input-label">
                                            <input
                                                defaultValue={cashdesk?.closedAt ? formatDate(cashdesk?.closedAt) : "—"}
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Closed At")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Receipts")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>

                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                defaultValue={cashdesk?.firstChequeNumber || "-"}
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("First Cheque Number")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <input
                                                defaultValue={cashdesk?.lastChequeNumber || "-"}
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Last Cheque Number")}
                                            </span>
                                        </label>
                                    </div>
                                </div>

                                <div className="input-item">
                                    <div className="component-input">
                                        <label className="input-label">
                                            <input
                                                defaultValue={cashdesk?.chequesQuantity || "-"}
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Cheques Quantity")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                    <div className="card-icon">
                                        <i className="icon-information" />
                                    </div>
                                    <div className="card-text">
                                        <p className="card-title">{__("Sales")}</p>
                                        <p className="card-subtitle" />
                                    </div>
                                    </div>
                                </div>

                                <div className="input-item">
                                    <div className="component-input">
                                        <label className="input-label">
                                            <NumericFormat
                                                defaultValue={cashdesk?.total.sale?.paid?.cash /100 || "0"}
                                                displayType="text"
                                                thousandSeparator=" "  
                                                decimalSeparator=","
                                                decimalScale={2}    
                                                suffix={` ${__("sum")}`} 
                                                fixedDecimalScale={true}
                                                renderText={(value) => <input className="input input-disabled" type="text" value={value} disabled />}
                                            />
                                            <span className="input-text">{__("Total Sale")}</span>
                                        </label>
                                    </div>

                                    <div className="component-input">
                                        <label className="input-label">
                                            <NumericFormat
                                                defaultValue={cashdesk?.total.sale?.paid?.cash /100 || "0"}
                                                displayType="text"
                                                thousandSeparator=" "
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                suffix={` ${__("sum")}`}
                                                renderText={(value) => <input className="input input-disabled" type="text" value={value} disabled />}
                                            />
                                            <span className="input-text">{__("Total Sale Cash")}</span>
                                        </label>
                                    </div>
                                </div>

                                <div className="input-item">
                                    <div className="component-input">
                                        <label className="input-label">
                                            <NumericFormat
                                                defaultValue={cashdesk?.total.sale?.paid?.cash /100 || "0"}
                                                displayType="text"
                                                thousandSeparator=" "
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                suffix={` ${__("sum")}`}
                                                renderText={(value) => <input className="input input-disabled" type="text" value={value} disabled />}
                                            />
                                            <span className="input-text">{__("Total Sale Card")}</span>
                                        </label>
                                    </div>

                                    <div className="component-input">
                                        <label className="input-label">
                                            <input
                                                defaultValue={cashdesk?.total.sale?.quantity || "0"}  
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                            />
                                            <span className="input-text">{__("Total Sale Quantity")}</span>
                                        </label>
                                    </div>
                                </div>

                                <div className="component-input">
                                    <label className="input-label">
                                        <NumericFormat
                                            defaultValue={cashdesk?.total.sale?.VAT /100 || "0"}                                   displayType="text"
                                            thousandSeparator=" "
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            suffix={` ${__("sum")}`}
                                            renderText={(value) => <input className="input input-disabled" type="text" value={value} disabled />}
                                        />
                                        <span className="input-text">{__("Total Sale VAT")}</span>
                                    </label>
                                </div>
                            </div>

                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                    <div className="card-icon">
                                        <i className="icon-information" />
                                    </div>
                                    <div className="card-text">
                                        <p className="card-title">{__("Refunds")}</p>
                                        <p className="card-subtitle" />
                                    </div>
                                    </div>
                                </div>

                                <div className="input-item">
                                    <div className="component-input">
                                        <label className="input-label">
                                            <NumericFormat
                                                defaultValue={cashdesk?.refund?.paid?.total /100 || "0"}                                    displayType="text"
                                                thousandSeparator=" "
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                suffix={` ${__("sum")}`}
                                                renderText={(value) => <input className="input input-disabled" type="text" value={value} disabled />}
                                            />
                                            <span className="input-text">{__("Total Refund")}</span>
                                        </label>
                                    </div>

                                    <div className="component-input">
                                        <label className="input-label">
                                            <NumericFormat
                                                defaultValue={cashdesk?.refund?.cash?.total /100 || "0"}
                                                displayType="text"
                                                thousandSeparator=" "
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                suffix={` ${__("sum")}`}
                                                renderText={(value) => <input className="input input-disabled" type="text" value={value} disabled />}
                                            />
                                            <span className="input-text">{__("Total Refund Cash")}</span>
                                        </label>
                                    </div>
                                </div>

                                <div className="input-item">
                                    <div className="component-input">
                                        <label className="input-label">
                                            <NumericFormat
                                                defaultValue={cashdesk?.refund?.card?.total /100 || "0"}
                                                displayType="text"
                                                thousandSeparator=" "
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                suffix={` ${__("sum")}`}
                                                renderText={(value) => <input className="input input-disabled" type="text" value={value} disabled />}
                                            />
                                            <span className="input-text">{__("Total Refund Card")}</span>
                                        </label>
                                    </div>

                                    <div className="component-input">
                                        <label className="input-label">
                                            <NumericFormat
                                                defaultValue={cashdesk?.refund?.quantity /100 || "0"}
                                                displayType="text"
                                                thousandSeparator=" "
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                suffix={` ${__("sum")}`}
                                                renderText={(value) => <input className="input input-disabled" type="text" value={value} disabled />}
                                            />
                                            <span className="input-text">{__("Total Refund Quantity")}</span>
                                        </label>
                                    </div>
                                </div>

                                <div className="component-input">
                                    <label className="input-label">
                                        <NumericFormat
                                            defaultValue={cashdesk?.refund?.VAT /100 || "0"}
                                            displayType="text"
                                            thousandSeparator=" "
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            suffix={` ${__("sum")}`}
                                            renderText={(value) => <input className="input input-disabled" type="text" value={value} disabled />}
                                        />
                                        <span className="input-text">{__("Total Refund VAT")}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <ZReportChequeTable hideBreadcrumbs={true} />
        </div>
    );
};

export default ZReportComponentView;