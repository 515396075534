import React, { useEffect, useState } from "react";
import { IMaskInput } from "react-imask";
import { useSelector } from "react-redux";
import { ApiService } from "../../../utils/ApiService";
import { successToast, errorToast } from "../../../utils/toast";
import { searchClientsQuery } from "../../../utils/globalAPIRequests/searchClients";
import { Box } from "@mui/material";
import { translate } from "../../../translations/translate";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../components/global/Button";
import PageHeader from "../../../components/PageHeader";
import UserLinkCard from "../../../components/UserLinkCard";
import cleanseObject from "../../../utils/cleanseObject";
import BreadCrumbs from "../../../components/BreadCrumbs";
import SelectComponent from "../../../components/Select";
import "./styles.sass";
import DragAndDrop from "../../DragAndDrop";
import config from "../../../config";
import useApiHook from "../../../hooks/useApiHook";
import UIAvatar from "../../../components/Avatar";
import { getTokenData, replaceNewlinesWithSpaces } from "../../../utils/helper";
import TextArea from "../../../components/TextArea/TextArea";

const PageBusinessCreate = () => {
    // USE LOCATION
    const { state } = useLocation();
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (state?.client?._id) {
            handleOwner(state.client);
        }
    }, [state?.client?._id]);

    const navigate = useNavigate();
    const PhoneMask = "+998 (00) 000-00-00";
    const PinflMask = "0000 0000 0000 00";
    const TinMask = "000 000 000";
    const tinMask = [
        {
            mask: TinMask,
        },
        {
            mask: PinflMask
        }
    ];
    const phoneEmailMask = [
        {
            mask: PhoneMask,
        },
    ];

    const storedUserData = useSelector((state) => state.authReducer);
    const token = storedUserData.token;
    const decodedToken = getTokenData(token);
    const isClient = storedUserData.role === "client";
    const isDealer = decodedToken?.role?.title === "Dealer";
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    const { data } = useApiHook(`${config.API_BASE_URL}/businesses/types/list`);
    const businessTypes = data?.data;

    const businessTypesList = businessTypes ? businessTypes.map(item => {
        return {
            value: item.code.en,
            label: `${item.title[lang]} (${item.code[lang]})`
        };
    }) : [];

    const [currentDocType, setCurrentDocType] = useState("");
    const [currentCustomDescription, setCurrentCustomDescription] = useState("");

    const [logoUrl, setLogoUrl] = useState("");

    // FOR BUSINESS DOCUMENTS
    const [isDragNDropOpen, setIsDragNDropOpen] = useState(false);
    const handleDragNDropOpen = () => {
        setIsDragNDropOpen(!isDragNDropOpen);
    };

    // FOR BUSINESS LOGO
    const [isLogoDragNDropOpen, setIsLogoDragNDropOpen] = useState(false);
    const handleLogoDragNDropOpen = () => {
        setIsLogoDragNDropOpen(!isLogoDragNDropOpen);
    };
    const handleSetLogo = (url) => {
        setLogoUrl(url.url);
        setBusiness({
            ...business,
            avatar: url.id,
        });
    };

    const [documents, setDocuments] = useState([
        {
            index: 0,
            documentType: {
                key: "passportScan",
                description: "Owner's Passport Scan"
            }
        },
        {
            index: 1,
            documentType: {
                key: "companyCertificate",
                description: "Your Company Certificate"
            }
        },
        {
            index: 2,
            documentType: {
                key: "rentContract",
                description: "Rent Contract (will be used for the first Branch as well)"
            }
        },
    ]);

    const handleSetDocument = (obj) => {
        let documentsTemp = documents;
        if (currentDocType === "custom") {
            const index = documentsTemp.length;
            const docObject = {
                index: index,
                documentType: {
                    key: currentDocType,
                    description: currentCustomDescription
                },
                _id: obj.id,
                name: obj.name
            };
            if (Array.isArray(documentsTemp)) {
                documentsTemp.push(docObject);
            }
            else {
                documentsTemp = [];
                documentsTemp.push(docObject);
            }
            setCurrentCustomDescription("");
        }
        else {
            documentsTemp.forEach((doc, i, arr) => {
                if (doc.documentType.key === currentDocType) {
                    arr[i]["_id"] = obj.id;
                    arr[i]["name"] = obj.name;
                }
            });
        }
        setDocuments([...documentsTemp]);
        setCurrentDocType(null);
        setBusiness({
            ...business,
            documents: documents,
        });
    };

    const handleCustomDocumentDescription = (index, description) => {
        const documentsTemp = documents;
        documentsTemp[index].documentType.description = description;
        setDocuments([...documentsTemp]);
        setBusiness({
            ...business,
            documents: documents,
        });
    };

    const deleteDoc = (index) => {
        let documentsTemp = documents;
        if (documentsTemp[index].documentType.key === "custom") {
            if (index > -1) {
                documentsTemp.splice(index, 1);
            }
        }
        else {
            delete documentsTemp[index]._id;
            delete documentsTemp[index].name;
        }
        setDocuments(documentsTemp);
        setBusiness({
            ...business,
            documents: documents,
        });
    };

    const [business, setBusiness] = useState({
        tin: "",
        pinfl: "",
        name: "",
        avatar: null,
        businessType: null,
        address: {
            legal: {
                state: "Uzbekistan",
                region: "",
                city: "",
                district: "",
                street: "",
                house: "",
                ZIPCode: "",
            },
            actual: {
                state: "Uzbekistan",
                region: "",
                city: "",
                district: "",
                street: "",
                house: "",
                ZIPCode: "",
            },
        },
        phone: null,
        vat: null,
        owner: null,
        responsiblePerson: {
            name: {
                first: null,
                last: null,
                middle: null,
            },
            phone: null,
            clientID: null,
        },
        documents: null,
    });

    const [ownerSearch, setOwnerSearch] = useState("");
    const [owner, setOwner] = useState(null);
    const [ownerSearchResults, setOwnerSearchResults] = useState([]);
    const [respPersonSearch, setRespPersonSearch] = useState("");
    const [respPerson, setRespPerson] = useState(null);
    const [respPersonSearchResults, setRespPersonSearchResults] = useState([]);

    useEffect(() => {
        if (ownerSearch.length > 0) {
            (async () => {
                return await searchClientsQuery(`${ownerSearch}${isDealer ? `&dealerID=${decodedToken?.ID}` : ""}`, token);
            })().then((result) => {
                setOwnerSearchResults(result);
            });
        } else {
            setOwnerSearchResults(null);
        }
    }, [ownerSearch]);

    const handleOwner = (ownerObj) => {
        setOwner(ownerObj);
        setBusiness({
            ...business,
            owner: ownerObj?._id,
        });
        setOwnerSearch("");
        setOwnerSearchResults(null);
    };

    const handleBusinessNameChange = (e) => {
        let value = e.target.value;

        // Convert the value to uppercase
        value = value.toUpperCase();

        // Remove unwanted symbols (single quote and double quote)
        value = value.replace(/['"]/g, "");

        // Update the state with the transformed value
        setBusiness({
            ...business,
            name: value,
        });
    };

    useEffect(() => {
        setBusiness({
            ...business,
            owner: owner?._id,
        });
    }, [owner]);

    useEffect(() => {
        if (respPersonSearch.length > 0) {
            (async () => {
                return await searchClientsQuery(`${respPersonSearch}${isDealer ? `&dealerID=${decodedToken?.ID}` : ""}`, token);
            })().then((result) => {
                setRespPersonSearchResults(result);
            });
        } else {
            setRespPersonSearchResults(null);
        }
    }, [respPersonSearch]);

    const handleResponsiblePerson = (respPersonObj) => {
        setRespPerson(respPersonObj);
        setBusiness({
            ...business,
            responsiblePerson: {
                name: {
                    first: respPersonObj?.name?.first,
                    last: respPersonObj?.name?.last,
                    middle: respPersonObj?.name?.middle,
                },
                phone: respPersonObj?.phone,
                clientID: respPersonObj?._id,
            },
        });
        setRespPersonSearch("");
        setRespPersonSearchResults(null);
    };

    const onCancelClick = function () {
        navigate("/businesses");
    };

    const onSubmit = (e) => {
        e.preventDefault();
        // Cleaning object from null values
        let body = cleanseObject(business);
        // Remove spaces from businessName
        body.name = business.name?.trim();

        // CHECKING IF REQUIRED FIELDS ARE FILLED
        if (!(body.name && business.owner)) {
            let emptyField = "";

            if (!body.name) emptyField += "Business Name";

            if (!business.owner) emptyField += " Business Owner";
            // if (!isRequiredInputFilled) emptyField += " Tin/Pinfl";
            errorToast(__(`${emptyField} fields is not filled`));
            return;
        }

        if (isSubmitting) return;

        setIsSubmitting(true);
        ApiService.postEvent("/businesses/create", token, body)
            .then((response) => {
                if (response.status === 201) {
                    successToast(__("Successfully created"));
                    setIsSubmitting(false);

                    navigate("/branches/create", {
                        state: {
                            business: response.data,
                            isNewBusiness: true,
                        },
                    });
                } else {
                    errorToast(__(response.message));
                }
            })
            .catch((error) => {
                setIsSubmitting(false);
                errorToast(__(error.message));
            });
    };

    if (isClient && !owner && !respPerson) {
        const clientObj = {
            name: {
                first: storedUserData?.profileData?.name?.first,
                last: storedUserData?.profileData?.name?.last,
                middle: storedUserData?.profileData?.name?.middle,
            },
            displayName: {
                first: storedUserData?.profileData?.displayName?.first,
                last: storedUserData?.profileData?.displayName?.last,
                middle: storedUserData?.profileData?.displayName?.middle,
            },
            _id: storedUserData?.profileData?._id,
            phone: storedUserData?.profileData?.phone,
        };
        handleOwner(clientObj);
        handleResponsiblePerson(clientObj);
    }

    const buttons = [
        {
            name: "Back",
            link: "/businesses",
            state: {},
            className: "outline",
        },
    ];

    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Businesses", link: "/businesses" },
                    { label: "Create" },
                ]}
            />
            <div className="page component-page-business-create">
                <form onSubmit={onSubmit} className="page-inner">
                    <PageHeader
                        name={__("Create Business")}
                        buttons={buttons}
                    />
                    <div className="cards">
                        <div className="left-side-cards">
                            {/* Business Information */}
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information"></i>
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Business Information")}
                                            </p>
                                            <p className="card-subtitle"></p>
                                        </div>
                                    </div>
                                </div>
                                {/* 1 */}
                                <div className="input-column">
                                    <UIAvatar
                                        onClick={handleLogoDragNDropOpen}
                                        url={logoUrl || ""}
                                        fileId={business.avatar}
                                        onRemoveSuccess={() => setLogoUrl("")}
                                    />
                                    <div className="input-row">
                                        <div className="input-item">
                                            {/* Business Name */}
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        onChange={handleBusinessNameChange}
                                                        className="input"
                                                        type="text"
                                                        placeholder={__(
                                                            "Enter your company name",
                                                        )}
                                                        value={business?.name}
                                                    />
                                                    <span className="input-text">
                                                        {__("Business Name")}
                                                    </span>
                                                </label>
                                            </div>
                                            {/* TIN */}
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <IMaskInput
                                                        mask={tinMask}
                                                        unmask
                                                        value={business?.tin}
                                                        onAccept={(value) => {
                                                            setBusiness({
                                                                ...business,
                                                                tin: value,
                                                            });
                                                        }}
                                                        className="input"
                                                        type="text"
                                                        placeholder="___ ___ ___"
                                                        minLength={9}
                                                        maxLength={17}
                                                    />
                                                    <span className="input-text">
                                                        {__("TIN / PINFL")}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        {/* 2 */}
                                        <div className="input-item">
                                            {/* Business Type */}
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <span className="input-text">
                                                        {__("Business Type")}
                                                    </span>

                                                    <SelectComponent
                                                        options={businessTypesList}
                                                        value={businessTypesList.find(item => item.value === business.businessType)}
                                                        placeholder="Select the type of business"
                                                        onChange={(e) =>
                                                            setBusiness({
                                                                ...business,
                                                                businessType: e.value,
                                                            })
                                                        }
                                                    />
                                                </label>
                                            </div>
                                            {!isClient && (
                                                <div className="component-input smart-search-container">
                                                    <label
                                                        className="input-label"
                                                    >
                                                        <input
                                                            onChange={(e) => {
                                                                setOwnerSearch(
                                                                    e.target.value,
                                                                );
                                                                setOwner("");
                                                            }}
                                                            value={
                                                                owner ? "" : ownerSearch
                                                            }
                                                            placeholder={__(
                                                                "Start typing...",
                                                            )}
                                                            className="input"
                                                            type="text"
                                                        />
                                                        <span className="input-text">
                                                            {__("Owner Search")}
                                                        </span>
                                                    </label>
                                                    {ownerSearchResults?.length > 0 && (
                                                        <div className="smart-search-list">
                                                            {ownerSearchResults &&
                                                                ownerSearchResults?.map(
                                                                    (item, i) => (
                                                                        <Box
                                                                            display="flex"
                                                                            justifyContent="center"
                                                                            key={i}
                                                                        >
                                                                            <UserLinkCard
                                                                                avatar={
                                                                                    owner?.avatar ||
                                                                                    ""
                                                                                }
                                                                                name={`${item.name.first} ${item.name.last}`}
                                                                                classNames={
                                                                                    "with-padding bordered hoverable"
                                                                                }
                                                                                onClick={() =>
                                                                                    handleOwner(
                                                                                        item,
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Box>
                                                                    ),
                                                                )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        <div className="input-item">
                                            {owner && (
                                                <div className="component-input no-input">
                                                    <label className="input-label">
                                                        <span className="input-text">
                                                            {__("Owner")}
                                                        </span>
                                                        <UserLinkCard
                                                            avatar={owner?.avatar || ""}
                                                            name={`${owner?.name?.first} ${owner?.name?.last}`}
                                                            classNames={
                                                                "with-padding input input-view"
                                                            }
                                                        />
                                                    </label>
                                                </div>
                                            )}
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <IMaskInput
                                                        type="tel"
                                                        className="mask-on-input card-input input"
                                                        placeholder="+998 (00) 000-00-00"
                                                        mask={phoneEmailMask}
                                                        autoComplete="off"
                                                        value={business?.phone}
                                                        onAccept={(value) => {
                                                            setBusiness({
                                                                ...business,
                                                                phone: value,
                                                            });
                                                        }}
                                                    />
                                                    <span className="input-text">
                                                        {__("Phone")}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Documents */}
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-tie"></i>
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Documents")}
                                            </p>
                                            <p className="card-subtitle">{__("Upload the required documents")}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="files-block">
                                    {Array.isArray(documents) && documents.map((doc, index) => (
                                        <div className="file-row" key={doc.index}>
                                            <div className="file-card disabled">
                                                {"" &&
                                                    <img src="" alt="." className="icon" />
                                                }
                                                <p className="text">{doc.name || ""}</p>
                                                <p className="label">{__(doc.documentType.description)}</p>
                                            </div>
                                            <div className={`file-card ${(doc.documentType.key === "custom") ? "" : "disabled"}`}>
                                                <input
                                                    value={__(doc.documentType.description) || ""}
                                                    disabled={!(doc.documentType.key === "custom")}
                                                    onChange={(e) => {
                                                        handleCustomDocumentDescription(index, e.target.value);
                                                    }}
                                                    type="text"
                                                    className="text"
                                                />
                                            </div>
                                            <div className="component-card-button">
                                                {doc?._id
                                                    ? (
                                                        <button
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                deleteDoc(index);
                                                            }}
                                                            className="btn squared error"
                                                        >
                                                            <i className="icon-delete"></i>
                                                        </button>
                                                    )
                                                    : (
                                                        <button
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setCurrentDocType(doc.documentType.key);
                                                                handleDragNDropOpen();
                                                            }}
                                                            className="btn squared"
                                                        >
                                                            <i className="icon-upload"></i>
                                                        </button>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    ))
                                    }
                                    {documents.length < 10 &&
                                        <div className="file-row" >
                                            <div className="file-card disabled">
                                                <p className="label">{__("Custom Document")}</p>
                                            </div>
                                            <div className={"file-card "}>
                                                <input
                                                    placeholder={__("Enter description for your document")}
                                                    onChange={(e) => {
                                                        setCurrentDocType("custom");
                                                        setCurrentCustomDescription(e.target.value);
                                                    }}
                                                    type="text"
                                                    className="text"
                                                    value={currentCustomDescription}
                                                />
                                            </div>

                                            <div className="component-card-button">
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setCurrentDocType("custom");
                                                        handleDragNDropOpen();
                                                    }}
                                                    className="btn squared"
                                                >
                                                    <i className="icon-upload"></i>
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {/* Business Address */}
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-business-building-location"></i>
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Legal Address")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* 1 */}
                                <div style={{ marginTop: "24px" }}>
                                    <TextArea
                                        label={__("Address")}
                                        value={business.address?.legal?.region}
                                        onChange={(e) => setBusiness({
                                            ...business,
                                            address: {
                                                ...business.address,
                                                legal: {
                                                    ...business.address?.legal,
                                                    region: replaceNewlinesWithSpaces(e.target.value),
                                                },
                                            },
                                        })}
                                        onBlur={(e) => {
                                            setBusiness({
                                                ...business,
                                                address: {
                                                    ...business.address,
                                                    actual: {
                                                        ...business.address?.actual,
                                                        region: business.address?.actual?.region
                                                            || e.target.value,
                                                    },
                                                },
                                            });
                                        }}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="component box-save">
                        <div className="not-save-item">
                            <div className="not-save-icon">
                                <i className="icon-saved"></i>
                            </div>
                            <div className="not-save-text">
                                <p className="save-text">
                                    {__("Not Saved Yet")}
                                </p>
                            </div>
                        </div>
                        <div className="not-save-button">
                            <Button
                                className="btn outline sm"
                                text={__("Cancel")}
                                onClick={onCancelClick}
                                type="button"
                            />
                            <Button
                                className="btn sm"
                                text={__("Save")}
                                type="submit"
                                disabled={isSubmitting}

                            />
                        </div>
                        {isDragNDropOpen && (
                            <DragAndDrop
                                setter={handleSetDocument}
                                closeDragNDrop={handleDragNDropOpen}
                                uploadType={"document"}
                                docModel={owner?._id ? "Client" : "User"}
                                ownerID={owner?._id || storedUserData._id}
                                isUpload={"true"}
                            />
                        )}
                        {isLogoDragNDropOpen &&
                            <DragAndDrop
                                setter={handleSetLogo}
                                closeDragNDrop={handleLogoDragNDropOpen}
                                uploadType={"document"}
                                docModel={owner?._id ? "Client" : "User"}
                                ownerID={owner?._id || storedUserData._id}
                                isUpload={"true"}
                                isImage={true}
                            />
                        }
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageBusinessCreate;
