import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApiService } from "../../../utils/ApiService";
import { successToast, errorToast } from "../../../utils/toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { searchTasnif } from "../../../utils/globalAPIRequests/searchTasnif";
import { searchCategories } from "../../../utils/globalAPIRequests/searchCategories";
import { Box } from "@mui/material";
import { IMaskInput } from "react-imask";
import { NumericFormat } from "react-number-format";
import { translate } from "../../../translations/translate";

import Button from "../../../components/global/Button";
import PageHeader from "../../../components/PageHeader";
import cleanseObject from "../../../utils/cleanseObject";
import ProductCard from "../../../components/ProductCard";
import SearchResultCard from "../../../components/SearchCard";

import "./styles.sass";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import BasicDatePicker from "../../../components/DatePicker";
import { formatDate } from "../../../utils/formatDate";
import config from "../../../config";
import useApiHook from "../../../hooks/useApiHook";
import SelectComponent from "../../../components/Select";
import { ownerTypeValues, isMarkedValues, VATValues, productTypes } from "../../../utils/consts";

const committentMask = [
    {
        mask: "000 000 000",
    },
    {
        mask: "0000 0000 0000 00"
    }
];

const PageProductCreate = ({ pageAsModal, closeModal }) => {
    const lang = useSelector((state) => state.languageReducer.lang);

    const __ = (key) => translate(lang, key);

    const navigate = useNavigate();
    const { state } = useLocation();
    const { businessID } = useParams();
    const storedUserData = useSelector((state) => state.authReducer);
    const warehouseStatus = useSelector(state => state.warehouse.warehouseStatus);
    const token = storedUserData.token;
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    // SEARCH FOR CATEGORIES
    const url = `${config.API_BASE_URL}/businesses/${state?.business._id || businessID}/product-categories?`;
    const { data } = useApiHook(url);

    const [product, setProduct] = useState({
        title: {
            uz: null,
            ru: null,
            en: null,
        },
        description: {
            uz: null,
            ru: null,
            en: null,
        },
        ownerType: null,
        img: null,
        price: 0,
        discount: {
            isActive: false,
            value: {
                amount: 0,
                percentage: 0,
            },
            activeThrough: {
                startDate: null,
                endDate: null,
            },
        },
        productType: "product",
        purchasePrice: 0,
        VAT: 12,
        inStock: 0,
        units: "",
        article: "",
        isMarked: null,
        categories: null,
        businessID: state?.business?.id || state?.business?._id || businessID,
        productTasnif: {
            icps: null,
            name: {
                uz: null,
                ru: null,
            },
            label: null,
            internationalCode: null,
            usePackage: null,
            dates: {
                createdAt: null,
                updatedAt: null,
            },
            packages: [
                {
                    code: null,
                    icps: null,
                    name: {
                        uz: null,
                        ru: null,
                        en: null,
                    },
                },
            ],
        },
        committentTIN: null,
    });
    const [discountType, setDiscountType] = useState("amount");

    // Index of the selected Unit. It helps to translate unit on view and select tasnif packageCode
    // But actual value will save in the product state
    const [selectedUnit, setSelectedUnit] = useState(-1);
    // Tasnif's units array
    const [tasnifUnits, setTasnifUnits] = useState([]);
    const [tasnifSearch, setTasnifSearch] = useState("");
    const [tasnifProduct, setTasnifProduct] = useState(null);
    const [tasnifSearchResults, setTasnifSearchResults] = useState([]);

    const [categoriesSearch, setCategoriesSearch] = useState("");
    const [categories, setCategories] = useState([]);
    const [categoriesSearchResults, setCategoriesSearchResults] = useState([]);

    // CALLBACKS
    const onStartDateChange = function (e) {
        setStartDate(e?.$d);
    };
    const onEndDateChange = function (e) {
        setEndDate(e?.$d);
    };
    const onVATSelected = function (e) {
        setProduct({
            ...product,
            VAT: e.value,
        });
    };
    const onOwnerTypeChange = function(e) {
        setProduct({
            ...product,
            ownerType: e.value,
        });
    };

    const onProductTypeChange = (e) => {
        setProduct({
            ...product,
            productType: e.value
        });
    };

    const onUnitSelected = function (e) {
        const packageIndex = e.value;
        const pack = tasnifProduct?.packages[packageIndex];
        setSelectedUnit(packageIndex);
        setProduct({
            ...product,
            units: pack?.name["lat"] || pack?.name["uz"],
            packageCode: pack?.code
        });
    };

    useEffect(() => {
        if (data?.data?.docs.length === 0) {
            errorToast(__("You should first create a category"));
            navigate(`/businesses/${state?.business?._id || businessID}/categories/create`, {
                state: {
                    business: {
                        _id: state?.business?._id || businessID,
                        name: state?.business?.name,
                        slug: state?.business?.slug || state?.business?._id,
                    }
                }
            });
        }
    }, [data]);

    useEffect(() => {
        if (tasnifSearch.length > 0) {
            (async () => {
                return await searchTasnif(tasnifSearch, token);
            })().then((result) => {
                setTasnifSearchResults(result);
            });
        } else {
            setTasnifSearchResults(null);
        }
    }, [tasnifSearch]);

    const handleTasnif = (tasnifObj) => {
        const tasnifUnitArr = tasnifObj?.packages?.map((pack, idx) => {
            if (lang === "ru")
                return {
                    label: pack?.name["ru"],
                    value: idx
                };
            else return {
                label: pack?.name["lat"] || pack?.name["uz"],
                value: idx
            };
        });
        setTasnifUnits(tasnifUnitArr);
        setTasnifSearch("");
        setTasnifProduct(tasnifObj);
        setProduct({
            ...product,
            title: {
                ...product.title,
                ru: tasnifObj?.name?.ru || "",
                uz: tasnifObj?.name?.uz || "",
                en: tasnifObj?.name?.uz || "",
            },
            productTasnif: tasnifObj,
            isMarked: Boolean(parseInt(tasnifObj.label)) || false,
        });
        setTasnifSearchResults([]);
    };

    const onIsMarkedChangeHandler = (value) => {
        const labeling = value === "0";
         setProduct((prevState) => ({
             ...prevState,
             isMarked: labeling
         }));
    };

    useEffect(() => {
        if (categoriesSearch.length > 0) {
            (async () => {
                return await searchCategories(
                    state?.business?._id,
                    categoriesSearch,
                    token,
                );
            })().then((result) => {
                setCategoriesSearchResults(result);
            });
        } else {
            setCategoriesSearchResults(null);
        }
    }, [categoriesSearch]);
    const handleCategory = (categoryObj) => {
        if (!categories.find((item) => item._id === categoryObj._id)) {
            setCategories([...categories, categoryObj]);
            setCategoriesSearch("");
        }
    };
    const deleteCategory = (categoryID) => {
        const index = categories.findIndex((item) => item._id === categoryID);
        if (index > -1) {
            categories.splice(index, 1);
            setCategories([...categories]);
        }
    };
    const formatCategories = () => {
        let categoriesArr = categories.map((item) => {
            return item._id;
        });
        setProduct({
            ...product,
            categories: categoriesArr,
        });
    };
    useEffect(() => {
        formatCategories();
    }, [categories]);

    const onCancelClick = function () {
        if (pageAsModal) {
            closeModal();
            return;
        }

        navigate(`/businesses/${state?.business?._id || businessID}/products`, {
            state: {
                business: {
                    id: state?.business?._id,
                    slug: state?.business?.slug,
                    name: state?.business?.name,
                },
            },
        });
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault(); // Предотвращаем переход
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();

        // Checking is product with discount
        if (product.discount.value.amount !== 0 && product.discount.value.percentage !== 0 && product.discount.value.amount !== "000" && product.discount.value.percentage !== "" && product.discount.value.percentage !== "0") {

            if (startDate && endDate) {
                // INCREMENTING DAY BY 1 BECAUSE DATEPICKER RETURNS 1 DAY LESS
                startDate.setDate(startDate.getDate() + 1);
                endDate.setDate(endDate.getDate() + 1);

                // SETTING DISCOUNT DATES
                product.discount.activeThrough = {
                    startDate: formatDate(new Date(startDate)),
                    endDate: formatDate(new Date(endDate)),
                };
            } else if (!startDate && !startDate) {
                errorToast(__("Please, choose Start Date and End Date of discount"));
                return false;
            }
        }



        const body = cleanseObject(product);
        if (!tasnifProduct) {
            errorToast(__("Please, choose a product from Tasnif Database"));
            return false;
        }
        if (categories.length < 1) {
            errorToast(__("Please, choose at least one category"));
            return false;
        }
        if (!product?.packageCode) {
            errorToast(__("Please, choose a  unit"));
            return false;
        }
        ApiService.postEvent(
            `/businesses/${state?.business?._id || businessID}/products`,
            token,
            body,
        )
            .then((response) => {
                if (response) {
                    successToast(__("Successfully created"));

                    if (pageAsModal) {
                        closeModal();
                        return;
                    }

                    navigate(
                      `/businesses/${
                        state?.business?.slug ||
                        state?.business?._id ||
                        businessID
                      }/products`,
                      {
                          state: {
                              business: state?.business,
                          },
                      },
                    );
                } else {
                    errorToast(__(response.message));
                }
            })
            .catch((error) => {
                errorToast(__(error.message));
            });
    };

    const buttons = [
        {
            name: "Back",
            link: `/businesses/${
                state?.business?.slug || state?.business?._id || businessID
            }/products`,
            state: {
                business: {
                    _id: state?.business?._id || businessID,
                    slug: state?.business?.slug,
                    name: state?.business?.name,
                },
            },
            className: "outline",
        },
    ];

    // when the page is used as a modal, these buttons are used
    const modalButtons = [
        {
            name: "Back",
            onClick: closeModal,
            className: "outline"
        },
    ];

    return (
        <div className={`page component-page-business-create ${pageAsModal && "asModal"}`}>
            <form onSubmit={onSubmit} className="page-inner">
                <PageHeader
                    buttons={pageAsModal ? modalButtons : buttons}
                    name={`${__("Create Product for")} "${
                        state?.business?.name
                    }"`}
                />
                <div className="cards">
                    <div className="left-side-cards">
                        <div className="component-box-card">
                            <div className="card-header">
                                <div className="card-info">
                                    <div className="card-icon">
                                        <i className="icon-information" />
                                    </div>
                                    <div className="card-text">
                                        <p className="card-title">
                                            {__("Product Information")}
                                        </p>
                                        <p className="card-subtitle" />
                                    </div>
                                </div>
                            </div>
                            {/* Start */}
                            <div className="input-item-container">
                                <div>
                                    <div className="input-item">
                                        <div className="component-input full smart-search-container">
                                            <label
                                                className="input-label"
                                            >
                                                <input
                                                    onChange={(e) => {
                                                        setTasnifSearch(e.target.value);
                                                    }}
                                                    placeholder={__("Search for ICPS, International Code or Title in Russian or Uzbek")}
                                                    className="input"
                                                    type="text"
                                                />
                                                <span className="input-text">
                                            {__("Search")}
                                        </span>
                                            </label>
                                            {tasnifSearchResults?.length > 0 &&
                                                tasnifSearch && (
                                                    <div className="smart-search-list">
                                                        {tasnifSearchResults &&
                                                            tasnifSearchResults?.map(
                                                                (item, i) => (
                                                                    <Box
                                                                        display="flex"
                                                                        justifyContent="center"
                                                                        key={i}
                                                                    >
                                                                        <ProductCard
                                                                            item={item}
                                                                            classNames={
                                                                                "with-padding bordered hoverable"
                                                                            }
                                                                            onClick={() =>
                                                                                handleTasnif(
                                                                                    item,
                                                                                )
                                                                            }
                                                                        />
                                                                    </Box>
                                                                ),
                                                            )}
                                                    </div>
                                                )}
                                        </div>

                                    </div>
                                    <div className="input-item">
                                        {tasnifProduct && tasnifProduct.icps && (
                                            <div className="info-card width-100">
                                                <p className="card-heading">
                                                    {__("Tasnif Product Info")}
                                                </p>
                                                <div className="card-content">
                                                    <p className="text">
                                                <span className="bold">
                                                    {__("Title")} :
                                                </span>
                                                    </p>
                                                    <p className="text">
                                                        {tasnifProduct.name?.ru || ""}
                                                    </p>
                                                    <p className="text">
                                                        {tasnifProduct.name?.uz || ""}
                                                    </p>
                                                    <p className="text">
                                                <span className="bold">
                                                     {__("ICPS")} :
                                                </span>{" "}
                                                        {tasnifProduct.icps}
                                                    </p>
                                                    {tasnifProduct.internationalCode && (
                                                        <p className="text">
                                                    <span className="bold">
                                                         {__("Int. Code")} :
                                                    </span>{" "}
                                                            {
                                                                tasnifProduct.internationalCode
                                                            }
                                                        </p>
                                                    )}
                                                    <p className="text">
                                                <span className="bold">
                                                    {`${__("Marking")}:`}
                                                </span>{" "}
                                                        {parseInt(
                                                            tasnifProduct.label,
                                                        ) === 1
                                                            ?  __("Required")
                                                            :  __("Not required") }
                                                    </p>
                                                    <Button
                                                        type="button"
                                                        className="btn outline sm"
                                                        text= {__("Clear")}
                                                        onClick={() => {
                                                            setTasnifProduct({});
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div>
                                    <div className="input-item">
                                        <div className="component-input full smart-search-container">
                                            <label
                                                className="input-label"
                                            >
                                                <input
                                                    onChange={(e) => {
                                                        setProduct({
                                                            ...product,
                                                            title: {
                                                                uz: e.target.value,
                                                                ru: e.target.value,
                                                                en: e.target.value
                                                            },
                                                        });
                                                    }}
                                                    placeholder={__(
                                                        "Enter title",
                                                    )}
                                                    className="input"
                                                    type="text"
                                                    required={true}
                                                    value={product.title.uz || ""}
                                                />
                                                <span className="input-text">
                                                    {__("Title")}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="input-item">
                                         <div className="component-input full">
                                            <label
                                                className="input-label"
                                            >
                                                <textarea
                                                    onChange={(e) =>
                                                        setProduct({
                                                            ...product,
                                                            description: {
                                                                uz: e.target.value,
                                                                ru: e.target.value,
                                                                en: e.target.value,
                                                            },
                                                        })
                                                    }
                                                    placeholder={__(
                                                        "Enter description",
                                                    )}
                                                    className="input"
                                                    rows={3}
                                                    value={product.description.uz || ""}
                                                />
                                                <span className="input-text">
                                                    {__("Description")}
                                                </span>
                                            </label>
                                         </div>
                                    </div>
                                    <div className="input-item">
                                        <div className="component-input full">
                                            <label
                                              className="input-label"
                                            >
                                                <span className="input-text">
                                                    {__("Marking")}
                                                </span>
                                                <SelectComponent
                                                  value={product?.isMarked ? isMarkedValues[0] : isMarkedValues[1]}
                                                  options={isMarkedValues}
                                                  onChange={(e) => onIsMarkedChangeHandler(e.value)}
                                                  translateLabel
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end */}
                        </div>
                        {/* Categories */}
                        <div className="component-box-card">
                            <div className="card-header">
                                <div className="card-info">
                                    <div className="card-icon">
                                        <i className="icon-information"></i>
                                    </div>
                                    <div className="card-text">
                                        <p className="card-title">
                                            {__("Categories")}
                                        </p>
                                        <p className="card-subtitle"></p>
                                    </div>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="component-input smart-search-container">
                                    <label
                                        className="input-label"
                                    >
                                        <input
                                            onChange={(e) => {
                                                setCategoriesSearch(
                                                    e.target.value,
                                                );
                                            }}
                                            placeholder={__("Start typing...")}
                                            className="input"
                                            type="text"
                                            value={categoriesSearch}
                                        />
                                        <span className="input-text">
                                            {__("Search Categories")}
                                        </span>
                                    </label>
                                    {categoriesSearchResults?.length > 0 &&
                                        categoriesSearch && (
                                            <div className="smart-search-list">
                                                {categoriesSearchResults &&
                                                    categoriesSearchResults?.map(
                                                        (item, i) => (
                                                            <Box
                                                                display="flex"
                                                                justifyContent="center"
                                                                key={i}
                                                            >
                                                                <SearchResultCard
                                                                    item={item}
                                                                    label={
                                                                        item
                                                                            .title
                                                                            ?.ru
                                                                    }
                                                                    classNames={
                                                                        "with-padding bordered hoverable"
                                                                    }
                                                                    onClick={() =>
                                                                        handleCategory(
                                                                            item,
                                                                        )
                                                                    }
                                                                />
                                                            </Box>
                                                        ),
                                                    )}
                                            </div>
                                        )}
                                </div>
                                {categories && categories.length > 0 && (
                                    <div className="info-card white-bg">
                                        <p className="card-heading">
                                            {__("Categories")}
                                        </p>
                                        <div className="card-content equal-padding">
                                            <div className="tags-container">
                                                {categories.map((category) => (
                                                    <div
                                                        className="tag"
                                                        key={category?._id}
                                                    >
                                                        {category?.title?.ru}
                                                        <span
                                                            className="delete-btn"
                                                            onClick={() =>
                                                                deleteCategory(
                                                                    category._id,
                                                                )
                                                            }
                                                        ></span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* Tax Information */}
                        <div className="component-box-card">
                            <div className="card-header">
                                <div className="card-info">
                                    <div className="card-icon">
                                        <i className="icon-information" />
                                    </div>
                                    <div className="card-text">
                                        <p className="card-title">
                                            {__("Tax Information")}
                                        </p>
                                        <p className="card-subtitle" />
                                    </div>
                                </div>
                            </div>
                            {/* 1 */}
                            <div className="input-item">
                                {/* International code */}
                                <div className="component-input ">
                                    <label
                                        className="input-label"
                                    >
                                        <input
                                            onChange={(e) => {
                                                setProduct({
                                                    ...product,
                                                    productTasnif: {
                                                        ...product.productTasnif,
                                                        internationalCode: e.target.value
                                                    }
                                                });
                                            }}
                                            onKeyDown={handleKeyDown}
                                            placeholder={__(
                                                "Enter International Code",
                                            )}
                                            className="input"
                                            type="text"
                                            value={
                                                product.productTasnif?.internationalCode ||
                                                ""
                                            }
                                        />
                                        <span className="input-text">
                                            {__("Intenational Code")}
                                        </span>
                                    </label>
                                    {tasnifSearchResults?.length > 0 &&
                                        tasnifSearch.internationalCode && (
                                            <div className="smart-search-list">
                                                {tasnifSearchResults &&
                                                    tasnifSearchResults?.map(
                                                        (item, i) => (
                                                            <Box
                                                                display="flex"
                                                                justifyContent="center"
                                                                key={i}
                                                            >
                                                                <ProductCard
                                                                    item={item}
                                                                    classNames={
                                                                        "with-padding bordered hoverable"
                                                                    }
                                                                    onClick={() =>
                                                                        handleTasnif(
                                                                            item,
                                                                        )
                                                                    }
                                                                />
                                                            </Box>
                                                        ),
                                                    )}
                                            </div>
                                        )}
                                </div>
                                {/* Owner Type */}
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <span className="input-text">
                                            {__("Owner Type")}
                                        </span>
                                        <SelectComponent
                                            value={ownerTypeValues.find(type => type.value === product?.ownerType) || ownerTypeValues[0]}
                                            options={ownerTypeValues}
                                            onChange={onOwnerTypeChange}
                                            translateLabel
                                        />
                                    </label>
                                </div>
                            </div>
                            {/* 2 */}
                            <div className="input-item">
                                {/* ICPS */}
                                <div className="component-input smart-search-container">
                                    <label
                                        className="input-label"
                                    >
                                        <input
                                            onChange={(e) => {
                                                setTasnifSearch({
                                                    ICPS: e.target.value,
                                                });
                                                setTasnifProduct(null);
                                            }}
                                            placeholder={__("Enter ICPS")}
                                            className="input"
                                            type="text"
                                            value={tasnifProduct?.icps || ""}
                                            disabled={!!tasnifProduct}
                                        />
                                        <span className="input-text">
                                            {__("ICPS")}
                                        </span>
                                    </label>
                                    {tasnifSearchResults?.length > 0 &&
                                        tasnifSearch.ICPS && (
                                            <div className="smart-search-list">
                                                {tasnifSearchResults &&
                                                    tasnifSearchResults?.map(
                                                        (item, i) => (
                                                            <Box
                                                                display="flex"
                                                                justifyContent="center"
                                                                key={i}
                                                            >
                                                                <ProductCard
                                                                    item={item}
                                                                    classNames={
                                                                        "with-padding bordered hoverable"
                                                                    }
                                                                    onClick={() =>
                                                                        handleTasnif(
                                                                            item,
                                                                        )
                                                                    }
                                                                />
                                                            </Box>
                                                        ),
                                                    )}
                                            </div>
                                        )}
                                </div>
                                {/* VAT */}
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <span className="input-text">
                                            {__("VAT (%)")}
                                        </span>
                                        <SelectComponent
                                            value={VATValues.find(vat => vat.value === product?.VAT) || 12}
                                            options={VATValues}
                                            onChange={onVATSelected}
                                            translateLabel
                                        />
                                    </label>
                                </div>
                            </div>
                            {/* 3 */}
                            <div className="input-item">
                                {/* Unit */}
                                <div className="component-input">
                                    <label
                                      className="input-label"
                                    >
                                        <span className="input-text">
                                            {__("Unit")}
                                        </span>
                                        <SelectComponent
                                          value={tasnifUnits.find(unit => unit.value === selectedUnit) || null}
                                          options={tasnifUnits}
                                          onChange={onUnitSelected}
                                          placeholder={__("Select")}
                                        />
                                    </label>
                                </div>
                                {/* Product Type */}
                                <div className="component-input">
                                    <label
                                      className="input-label"
                                    >
                                        <span className="input-text">
                                            {__("Type")}
                                        </span>
                                        <SelectComponent
                                          value={productTypes.find(type => type.value === product?.productType) || "-"}
                                          options={productTypes}
                                          onChange={onProductTypeChange}
                                          translateLabel
                                        />
                                    </label>
                                </div>
                            </div>
                            {/* 4 */}
                            {warehouseStatus ? (
                              <div className="input-item">
                                  {/* Article */}
                                  <div className="component-input">
                                      <label
                                        className="input-label"
                                      >
                                          <input
                                            onChange={(e) =>
                                              setProduct({
                                                  ...product,
                                                  article: e.target.value,
                                              })
                                            }
                                            placeholder={__("Enter Article")}
                                            className="input"
                                            type="text"
                                          />
                                          <span className="input-text">
                                            {__(" Article")}
                                        </span>
                                      </label>
                                  </div>
                              </div>
                            ) : (
                              <div className="input-item">
                                  {/* In Stock*/}
                                  <div className="component-input">
                                      <label
                                        className="input-label"
                                      >
                                          <NumericFormat
                                            className={"input"}
                                            required={true}
                                            defaultValue={0}
                                            onValueChange={(values) => {
                                                const {value} = values;
                                                setProduct({
                                                    ...product,
                                                    inStock: value,
                                                });
                                            }}
                                            placeholder={__("Enter in stock")}
                                            thousandSeparator={" "}
                                            inputMode={"numeric"}
                                          />
                                          <span className="input-text">
                                            {__("In Stock")}
                                        </span>
                                      </label>
                                  </div>
                              </div>
                            )}
                        </div>
                        {/* Price Information */}
                        <div className="component-box-card">
                            <div className="card-header">
                                <div className="card-info">
                                    <div className="card-icon">
                                        <i className="icon-information"/>
                                    </div>
                                    <div className="card-text">
                                        <p className="card-title">
                                            {__("Price Information")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="input-item">
                                {/* Price */}
                                <div className="component-input">
                                    <label
                                      className="input-label"
                                    >
                                        <NumericFormat
                                          className={"input"}
                                          required={true}
                                          onValueChange={(values) => {
                                              const {value} = values;

                                              setProduct({
                                                  ...product,
                                                    price: value.replace(
                                                        ".",
                                                        "",
                                                    ),
                                                });
                                            }}
                                            placeholder={__("Enter price")}
                                            thousandSeparator={" "}
                                            decimalSeparator={"."}
                                            inputMode={"numeric"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />
                                        <span className="input-text top-aligned">
                                            {__(" Price")}
                                        </span>
                                    </label>
                                </div>
                                {/* Purchase Price*/}
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <NumericFormat
                                            className={"input"}
                                            onValueChange={(values) => {
                                                const {value} = values;

                                                setProduct({
                                                    ...product,
                                                    purchasePrice:
                                                        value.replace(".", ""),
                                                });
                                            }}
                                            placeholder={__(
                                                "Enter Purchase Price",
                                            )}
                                            thousandSeparator={" "}
                                            decimalSeparator={"."}
                                            inputMode={"numeric"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />
                                        <span className="input-text">
                                            {__("Purchase Price")}
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div className="input-item">
                                {/* Discount amount */}
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        {discountType === "amount" && (
                                            <NumericFormat
                                                className={"input"}
                                                // required={true}
                                                onValueChange={(values) => {
                                                    const {
                                                        formattedValue,
                                                        value,
                                                    } = values;
                                                }}
                                                onBlur={(e) => {
                                                    if (!product?.price)
                                                        return false;
                                                    else if (
                                                        e.target.value.replaceAll(
                                                            /[,.\s]/g,
                                                            "",
                                                        ) <
                                                        product?.price.replaceAll(
                                                            /[,.\s]/g,
                                                            "",
                                                        )
                                                    )
                                                        setProduct({
                                                            ...product,
                                                            discount: {
                                                                ...product.discount,
                                                                value: {
                                                                    amount:
                                                                        e.target.value.replace(
                                                                            /[,.\s]/g,
                                                                            "",
                                                                        ) || 0,
                                                                },
                                                            },
                                                        });
                                                }}
                                                thousandSeparator={" "}
                                                decimalSeparator={"."}
                                                inputMode={"numeric"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                value={
                                                    `${+product.discount.value
                                                        .amount / 100}` || 0
                                                }
                                            />
                                        )}
                                        {discountType === "percentage" && (
                                            <input
                                                className={"input"}
                                                // required={true}
                                                onBlur={(e) => {
                                                    if(e.target.value.length === 0)
                                                        setProduct({
                                                            ...product,
                                                            discount: {
                                                                ...product.discount,
                                                                value: {
                                                                    percentage: 0
                                                                },
                                                            },
                                                        });
                                                }}
                                                onChange={(e) => {
                                                    const regexSuccess =
                                                        /^(0|[1-9][0-9]?|(100))?$/gm.test(
                                                            e.target.value,
                                                        ) ||
                                                        e.target.value === "";
                                                    if (!regexSuccess)
                                                        e.preventDefault();
                                                    else
                                                        setProduct({
                                                            ...product,
                                                            discount: {
                                                                ...product.discount,
                                                                value: {
                                                                    percentage:
                                                                    e.target
                                                                        .value
                                                                },
                                                            },
                                                        });
                                                }}
                                                value={
                                                    product?.discount?.value
                                                        ?.percentage
                                                }
                                            />
                                        )}
                                        <div className="button-toggler">
                                            <label
                                                className="button-toggler-item"
                                            >
                                                <input
                                                    className={"hidden-input"}
                                                    type="radio"
                                                    name={"discountType"}
                                                    value={"amount"}
                                                    onChange={() => {
                                                        setDiscountType(
                                                            "amount",
                                                        );
                                                    }}
                                                    defaultChecked={true}
                                                />
                                                <span className="btn-view">
                                                    {__("soums")}
                                                </span>
                                            </label>
                                            <label
                                                className="button-toggler-item"
                                            >
                                                <input
                                                    className={"hidden-input"}
                                                    type="radio"
                                                    name={"discountType"}
                                                    value={"percentage"}
                                                    onClick={() => {
                                                        setDiscountType(
                                                            "percentage",
                                                        );
                                                    }}
                                                />
                                                <span className="btn-view">
                                                    %
                                                </span>
                                            </label>
                                        </div>
                                        <span className="input-text">
                                            {__("Discount")}
                                            {" " + __(discountType)}
                                        </span>
                                    </label>
                                </div>
                                <div className="input-item-container">
                                    {/* Start Date */}
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                        <span className="input-text date-picker">
                                            {__("Discount Start Date")}
                                        </span>
                                            <StyledEngineProvider>
                                                <BasicDatePicker
                                                    value={startDate || ""}
                                                    selectedDate={startDate}
                                                    setSelectedDate={onStartDateChange}
                                                    onChange={onStartDateChange}
                                                />
                                            </StyledEngineProvider>
                                        </label>
                                    </div>
                                    {/* End Date */}
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                        >
                                            <span className="input-text date-picker">
                                                {__("Discount End Date")}
                                            </span>
                                            <StyledEngineProvider>
                                                <BasicDatePicker
                                                    value={endDate || ""}
                                                    selectedDate={endDate}
                                                    setSelectedDate={setEndDate}
                                                    onChange={onEndDateChange}
                                                />
                                            </StyledEngineProvider>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Supply Information */}
                        <div className="component-box-card">
                            <div className="card-header">
                                <div className="card-info">
                                    <div className="card-icon">
                                        <i className="icon-information"></i>
                                    </div>
                                    <div className="card-text">
                                        <p className="card-title">
                                            {__("Supply Information")}
                                        </p>
                                        <p className="card-subtitle"></p>
                                    </div>
                                </div>
                            </div>
                            <div className="input-item">
                                {/* Commitent TIN*/}
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                    >
                                        <IMaskInput
                                            mask={committentMask}
                                            unmask
                                            value={product?.committentTIN}
                                            onAccept={(value) => {
                                                setProduct({
                                                    ...product,
                                                    committentTIN: value
                                                });
                                            }}
                                            className="input"
                                            type="text"
                                            placeholder="___ ___ ___"
                                            minLength={11}
                                            maxLength={17}
                                        />
                                        <span className="input-text">
                                            {__("Commitent TIN/PINFL")}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="component box-save">
                    <div className="not-save-item">
                        <div className="not-save-icon">
                            <i className="icon-not-save"></i>
                        </div>
                        <div className="not-save-text">
                            <p className="save-text">{__("Not Saved Yet")}</p>
                        </div>
                    </div>
                    <div className="not-save-button">
                        <Button
                            className="btn outline sm"
                            text={__("Cancel")}
                            onClick={onCancelClick}
                            type="button"
                        />
                        <Button
                            className="btn sm"
                            text={__("Save")}
                            type="submit"
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default PageProductCreate;
