import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useLocation, useParams} from "react-router";

import {DataGrid} from "@mui/x-data-grid";
import {NumericFormat} from "react-number-format";
import {Box, colors, Typography} from "@mui/material";
import BreadCrumbs from "../../../components/BreadCrumbs";
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader";
import SelectComponent from "../../../components/Select";
import Preloader from "../../../components/Preloader";

import {translate} from "../../../translations/translate";
import {getParsedDate} from "../../../utils/getParsedDate";
import useApiHook from "../../../hooks/useApiHook";
import config from "../../../config";
import {useNavigate} from "react-router-dom";

const PageTransferView = () => {
    const navigate = useNavigate();
    const {state} = useLocation();
    const {transferId, businessID} = useParams();
    // Lang selector
    const lang = useSelector((state) => state.languageReducer.lang);
    // Pagination controller
    const controller = useSelector((state) => state.paginationControllerReducer);

    const baseUrl = `${config.API_BASE_URL}/businesses/${businessID}/invoice/${transferId}`;

    // States
    const [url, setUrl] = useState(baseUrl);

    const { loading, data } = useApiHook(url);
    const transfer = data?.data?.docs[0];

    // Handlers / functions
    const __ = (key) => translate(lang, key);

    const formatProductsArr = (products) => {
        return products?.map((product) => ({
            ...product,
            id: product._id
        }));
    };

    // Effects
    // TODO: implement table pagination

    const breadCrumbsArray = [
        {label: __("Withdrawals"), link: "/businesses"},
        {label: __( "View")},
    ];

    const productColumn = [
        {
            field: "article",
            headerName: __("Article"),
            flex: 1,
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={600}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                    onClick={() => {
                        navigate(
                          `/businesses/${state?.business?.slug ||
                          state?.business?._id ||
                          businessID
                          }/products/${row.productID.id}`,
                          {
                              state: {
                                  business: {
                                      id:
                                        state?.business._id ||
                                        businessID,
                                      slug:
                                        state?.business.slug ||
                                        businessID,
                                  },
                                  product: {
                                      id: row.productID.id,
                                      slug: row.productID.id,
                                  },
                              },
                          },
                        );
                    }}
                  >
                      {row.productID?.article}
                  </Typography>
                );
            }
        },
        {
            field: "productName",
            headerName: __("Name"),
            width: 300,
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={600}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                    onClick={() => {
                        navigate(
                          `/businesses/${state?.business?.slug ||
                          state?.business?._id ||
                          businessID
                          }/products/${row.productID.id}`,
                          {
                              state: {
                                  business: {
                                      id:
                                        state?.business._id ||
                                        businessID,
                                      slug:
                                        state?.business.slug ||
                                        businessID,
                                  },
                                  product: {
                                      id: row.productID.id,
                                      slug: row.productID.id,
                                  },
                              },
                          },
                        );
                    }}

                  >
                      {row.productID?.title?.uz}
                  </Typography>
                );
            }
        },
        {
            field: "barcode",
            headerName: __("Barcode"),
            flex: 1,
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                  >
                      {row?.productID?.productTasnif?.internationalCode}
                  </Typography>
                );
            }
        },
        {
            field: "labeling",
            headerName: __("Labeling"),
            flex: 1,
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                  >
                      {row?.productID?.isMarked ? "required" : "not required"}
                  </Typography>
                );
            }
        },
        {
            field: "purchasePrice",
            headerName: __("Purchase Price"),
            flex: 1,
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                  >
                      <NumericFormat
                        displayType="text"
                        value={row?.purchasePrice / 100}
                        thousandSeparator={" "}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale
                      />
                  </Typography>
                );
            }
        },
        {
            field: "sellingPrice",
            headerName: __("Selling Price"),
            flex: 1,
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                  >
                      <NumericFormat
                        displayType="text"
                        value={row?.price / 100}
                        thousandSeparator={" "}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale
                      />
                  </Typography>
                );
            }
        },
        {
            field: "amount",
            flex: 1,
            headerName: __("Amount"),
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                  >
                      <NumericFormat
                        displayType="text"
                        thousandSeparator={" "}
                        value={row?.count}
                      />
                  </Typography>
                );
            }
        },
    ];
    const products = formatProductsArr(transfer?.products);

    const headerValues = {
        invoice: transfer?.invoiceNumber,
        date: getParsedDate(transfer?.recieptDate),
        inn: transfer?.suplierINN,
        resPerson: transfer?.responsiblePerson,
        totalCount: transfer?.totalCount,
        branch: transfer?.toBranchID?.name,
        totalSum: transfer?.totalPrice
    };

    if (loading) return <Preloader />;

    return (
      <div>
          <BreadCrumbs breadcrumbs={breadCrumbsArray}/>
          <div className="withdrawal-page page-inner">
              <PageHeader name={__("View Transfer")}/>
              <Box
                sx={{marginTop: "24px"}}
              >
                  <div className="component-box-card">
                      <div className="card-header">
                          <div className="card-info">
                              <div className="card-icon">
                                  <i className="icon-branch"></i>
                              </div>
                              <div className="card-text">
                                  <p className="card-title">
                                      {__("Branch")}
                                  </p>
                              </div>
                          </div>
                      </div>
                      <div className="input-item">
                          <div className="component-input">
                              <label
                                className="input-label"
                              >
                                  <span className="input-text">
                                    {__("Source")}
                                  </span>
                                  <SelectComponent
                                    defaultValue={transfer?.fromBranchID?.name || __("Warehouse")}
                                    disabled
                                  />
                              </label>
                          </div>
                          <div className="component-input">
                              <label
                                className="input-label"
                              >
                                  <span className="input-text">
                                    {__("Receiver")}
                                  </span>
                                  <SelectComponent
                                    defaultValue={transfer?.toBranchID?.name || __("Warehouse")}
                                    disabled
                                  />
                              </label>
                          </div>
                      </div>
                  </div>
                  <Box sx={{overflowX: "scroll", marginTop: "24px", background: "var(--basic-white)", borderRadius: "6px"}}>
                      <TableHeader readOnly inputValues={headerValues} transferPage/>
                      <Box
                        sx={{
                            "&": {
                                margin: "0",
                                width: "100%"
                            },
                            "& .name-column--cell": {
                                color: colors.green[300],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                            },
                        }}
                      >
                          <DataGrid
                            rows={products}
                            rowCount={controller.total}
                            page={controller.page}
                            pageSize={controller.pageSize}
                            columns={productColumn}
                            {...config.TABLE_CONFIG(__)}
                          />
                      </Box>
                  </Box>
              </Box>
          </div>
      </div>
    );
};

export default PageTransferView;
