import StatusTag from "./components/StatusTag";
import CustomPagination from "./components/Pagination";

export const getLocaleText = (__) => ({
    footerRowSelected: (count) =>
        count !== 1
            ? `${count} ${__("rows selected")}`
            : `${count} ${__("row selected")}`,
});



const API_HOST = process.env.REACT_APP_API_HOST || "https://api.management.epos.uz";
const API_VERSION = "/v1";
const API_BASE_URL = API_HOST + API_VERSION;
export const DEFAULT_LANGUAGE = "en";

const TABLE_CONFIG = (translate) => ({
    sx: {
        height: "90vh",
        minHeight: "350px",
    },
    components: {
        UITable: <StatusTag />,
        Pagination: CustomPagination,
    },
    checkboxSelection: false,
    rowsPerPageOptions: [5, 10, 20, 50, 100],
    paginationMode: "server",
    localeText: {
        columnsPanelTextFieldLabel: translate("Find column") || "Find column",
        columnsPanelTextFieldPlaceholder: translate("Column title") || "Column title",
        columnsPanelHideAllButton: translate("Hide all") || "Hide all",
        columnsPanelShowAllButton: translate("Show all") || "Show all",
        columnMenuUnsort: translate("Unsort") || "Unsort",
        columnMenuSortAsc: translate("Sort by ASC") || "Sort by ASC",
        columnMenuSortDesc: translate("Sort by DESC") || "Sort by DESC",
        columnMenuFilter: translate("Filter") || "Filter",
        columnMenuHideColumn: translate("Hide") || "Hide",
        columnMenuShowColumns: translate("Show columns") || "Show columns",

        // Filter operators
        filterOperatorContains: translate ("contains") || "contains",
        filterOperatorEquals: translate  ("equals") || "equals",
        filterOperatorStartsWith: translate ("start with") || "start with",
        filterOperatorEndsWith: translate ("ends with") || "ends with",
        filterOperatorIsEmpty: translate ("is empty") || "is empty",
        filterOperatorIsNotEmpty: translate ("is not empty") || "is not empty",
        filterOperatorIsAnyOf: translate ("is any of") || "is any of",

        // Filter panel labels
        filterPanelColumns: translate("Columns") || "Columns",
        filterPanelOperator: translate("Operator") || "Operator",
        filterPanelValue: translate("Value") || "Value",
        


        // Input placeholder in filter panel
        filterPanelInputPlaceholder: translate("Filter value") || "Filter value"

    }
});

export default { API_BASE_URL, API_HOST, TABLE_CONFIG };
